import React from 'react'
import {observer} from 'mobx-react'

import {
  Divider,
  Typography,
  Drawer,
  Paper
} from '@material-ui/core';
//import {
//  VisibilityOff
//} from '@material-ui/icons';

import windowSize from 'react-window-size';
import { withStyles } from '@material-ui/core/styles'
import { SnackbarProvider} from 'notistack'
import ImageGroups from './ImageGroupsNew'
import PearlsText from './PearlsTextNew'
import ParentsChips from './ParentsChipsNew'
//import CtradlexTypeahead from './CtradlexTypeahead.js'
import {CtradlexSearch} from './ctradlexSearch.js'
import AppBarRadlex from './AppBarRadlexNew'
import BottomBar from './BottomBar'
import TreeView from './TreeViewNew'
import store from '../stores/newStore.js'
import styles from 'styles/styles.js'
import PropTypes from 'prop-types'
import cx from 'classnames'
import TextBox from 'components/TextBox'
import Progress, {ProgressPopover} from 'components/Progress'



export default
@withStyles(styles)
@windowSize
@observer
class Layout extends React.Component{
  constructor(props) {
    super(props);
    //console.log('layout props', this.props)
    this.state = {
      expanded: false,
      sideBarOpen: true
    };
    this.sideBarRef = React.createRef()
    this.mainDivRef = React.createRef()
    this.offSetWidth = 0

  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  resize = () => {
    this.setState({ pearlsHeight: this.pearlsElement.clientHeight });
    //console.log('big pearls height:', this.pearlsElement.clientHeight)
  }

  sideBarOpen = () => {
  this.setState({ sideBarOpen: true });
  };

  sideBarClose = () => {
    this.setState({ sideBarOpen: false });
  };

  componentDidUpdate() {
    //let newWidth = this.sideBarRef.current.offsetWidth || 100
  }

  componentDidMount(){
    this.setState({mainDivRef: this.mainDivRef})
  }

  render (){
    const { classes, windowHeight } = this.props;
    const {refreshTree, working} = store
    //const divRef = React.useRef();
    //console.log('windowHeight:', windowHeight)
    if (store.loading){
      return (
        <div style={{
          height:'25vh',
          width: '25vw',
          position: 'fixed',
          top: '50%',
          left: '50%',
          /* bring your own prefixes */
          transform: 'translate(-50%, -50%)'
        }}>{
          <Progress open={store.loading}/>
        }
        </div>
      )
    }
    //console.log('store in layout:', store.treeView.activeNode)
    let {Pearls, _id} = store.treeView.activeNode.document
    //console.log('pearls text:', Pearls)

    return (

  <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div
          className={classes.window}
          >
          <AppBarRadlex
            className={cx(classes.appBar, {
              [classes.appBarShift]: this.state.sideBarOpen,
            })}
            ctradlex={store.treeView.activeNode.document}
            position="static"
          />
            <Drawer
              variant="persistent"
              anchor="left"
              open={store.treeView.treeViewVisible}
              className={classes.sideBar}
              classes={{
                paper: classes.sideBarPaper,
              }}
            >
              <div
              ref = {this.sideBarRef}
                className={cx(classes.panel, classes.leftPanel)}
                >
                <CtradlexSearch onChange={(id)=>{refreshTree({id})}}/>
              <div
                  style={{
                    flex: 1,
                    height: '100%',
                    overflow: 'auto'
                  }}
                >
                  <TreeView
                    store={store.treeView}
                  />
                </div>
              </div>
            </Drawer>
            <div
              className={cx(classes.mainDiv, {
              [classes.mainDivResponsive]: store.treeView.treeViewVisible,
              })}
              ref={this.mainDivRef}
            >
              <ProgressPopover
                open={working}
                anchorEl={this.state.mainDivRef}
              />
              <div
                key={'middle panel'}
                className={cx(classes.panel, classes.middlePanel)}
              >
                <Paper
                className = {classes.parentsContainer}
                  style={{display:'flex', flexGrow: 0, flexDirection:'column', paddingTop: 10}}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={classes.backgroundText}
                  >
                    Parents
                  </Typography>
                  <ParentsChips
                      store={store.treeView.activeNode}
                    />
                </Paper>
                <div
                  className={classes.textFieldParent}
                >
                  <TextBox
                    caption='Pearls'
                    textBoxValue={Pearls}
                    authenticated={store.authenticated}
                    onUpdate={e=>store.treeView.activeNode.document.Pearls=e.target.value}
                    onCommit={()=>{store.treeView.activeNode.document.updateDb()}}
                  />
                </div>
              </div>
              <div
                className={cx(classes.panel, classes.rightPanel)}
              >
                <ImageGroups
                  document={store.treeView.activeNode.document}
                />
              </div>
            </div>
          <BottomBar
            store = {store}
          />
        </div>
      </SnackbarProvider>

    )
  }
}
