import { computed, observable, extendObservable, action, toJS } from 'mobx'


import TreeNode from './treeNodeStore.js'

export default class TreeView{
  @observable tree
  @observable activeNodeStore = null
  @computed
    get activeNode (){

      let returnVal = this.activeNodeStore || this.tree
      //console.log('trigger activeNode getter', this.tree)
      return(returnVal)
    }
    set activeNode(nodeToSet){
      //console.log('setting active node', nodeToSet.Name)
      this.activeNodeStore = nodeToSet
    }

  @observable hoverNode = {}
  @observable treeViewVisible = true
  @action toggleTreeViewVisible = ()=>{
    //console.log('toggling visible in store')
    this.treeViewVisible = !this.treeViewVisible
  }

  @observable scrollIntoView = true
  constructor(params, parent){
    let {socket = {}} = parent
    this.socket = socket
    this.emit = parent.emit
    this.emitStream = parent.emitStream
    this.store = parent
    this.authenticated = parent.authenticated
    this.showError = parent.showError
    this.showMessage = parent.showMessage
    //console.log('initiating in treeView')
    this.tree = new TreeNode(params, this)
    //console.log('completed tree')
    //console.log(this.tree)
  }

  allNodes(callback){
    const findNode = (testNode)=>{
      callback(testNode)
      if (testNode.children.length){
        testNode.children.map(node=>{findNode(node)})
      }
    }
    findNode(this.tree)
  }

  getNode(nodeId){
    let returnNodeSet = []
    let allNodes = new Set()
    const findNode = (testNode)=>{
      allNodes.add(testNode)
      if (testNode.children.length){
        testNode.children.map(node=>{findNode(node)})
      }
    }
    findNode(this.tree)
    for (let node of allNodes){
      if (node._id == nodeId){
        returnNodeSet.push(node)
      }
    }
    return returnNodeSet
  }

  clearUpdates(){
    this.allNodes(node=>node.clearUpdates())
  }
}
