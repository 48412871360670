import React from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import classNames from 'classnames'
//import UploadDrop from './UploadDrop.js';
import {observer} from 'mobx-react'
import {withStyles} from '@material-ui/core/styles'
import styles from 'styles/styles.js'
import Progress from 'components/Progress'

export default
@withStyles(styles)
@observer
class AddImage extends React.Component {
  constructor(props) {
    super(props);
  //  this.moveImage = this.moveCard.bind(this);
    this.state = {
      uploadURL:'',
      dimensions:{
        height: null,
        width: null
      },
      imgStyle: {
        width: 'auto',
        height: 'auto',
        margin: 'auto',
        display: 'block'
      },
      imgContainerStyle: {
        display:'flex',
      },
      mainDivStyle: {}
    };
    this.onImgLoad = this.onImgLoad.bind(this);
    //console.log(this.props.imageGroup.editImage)
  }
  onImgLoad = ({target:img}) => {
    console.log('loading image')
    var imgHeight = img.offsetHeight
    var imgWidth = img.offsetWidth
    var imgAspect = img.offsetWidth/img.offsetHeight
    var windowAspect = window.innerWidth/window.innerHeight
    var imgToWindowRtio = imgAspect/windowAspect

    if (imgToWindowRtio >= 1){
      this.setState({
        imgStyle:{
          width:'100%',
          height:'auto',
          margin: 'auto',
          display: 'block'
        }
      })
    }
    else {
      this.setState({
        imgStyle:{
          width:'auto',
          height:'100%',
          margin: 'auto',
          display: 'block'
        }
      })
    }
  }

  handleClose = () => {
    this.setState({uploadURL: ''})
    this.props.onClose()
  };

  handleUploadURL = async () => {
    let {uploadUrl} = this.props.imageGroup
    await uploadUrl(this.state.uploadURL).catch(err=>console.log('error uploading file'))
    this.handleClose()
    };

  handleDrop = async (acceptedFiles, rejectedFiles)=>{
    console.log('triggered on drop')
    await this.props.imageGroup.uploadImage(acceptedFiles)
    this.handleClose()
  }
  get progressBar(){
    return(
      <div style={{height:150, width:150}}><Progress open={true}/></div>
    )
  }

  get uploadElements(){
    const {classes} = this.props
    return(
      <div>
        <div style={{display:'flex', paddingBottom: 50}}>
          <TextField
            id='id upload by URL'
            label='paste URL to upload'
            value={this.state.uploadURL}
            fullWidth
            margin='normal'
            variant='outlined'
            style={{width:300, paddingRight: 20}}
            disabled={false}
            onChange={(e)=>{
              this.setState({uploadURL: e.target.value})
            }

              //this.handleUploadURL
            }
          />
          <Button
            label="Upload"
            onClick={this.handleUploadURL}
          >
          Upload
          </Button>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Dropzone
            onDrop={this.handleDrop}
          >
            {({getRootProps, getInputProps, isDragActive}) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames(classes.dropZoneStyle, {[classes.dropZoneActiveStyle]: isDragActive})}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <Typography> Drop files here...</Typography> :
                      <Typography>Drop files or click to upload.</Typography>
                  }
                </div>
              )
            }}
          </Dropzone>
        </div>
      </div>
    )
  }

  render() {
    let {imageGroup} = this.props
    const {editImage, uploading} = imageGroup
    const {classes} = this.props
    var imageProps = []
    var open = false
    if (editImage){
      imageProps = Object.keys(editImage)
      open = true
    }
    const uploadPreview = (this.props.imageGroup.uploadPreview) ? <img
            src={this.props.imageGroup.uploadPreview}
            height={150}
            width={150}
          /> : <div
            height={150}
            width={150}
            >
              Drag to upload
            </div>

    return (
      <Dialog
        title="Add Image"
        open={this.props.open}
        onClose={this.props.toggleVisibility}
      >
      <DialogTitle id="alert-dialog-title">{'Upload images or pdfs'}</DialogTitle>
        <DialogContent
          style={{display:'flex', flexDirection:'column'}}>
          {this.props.imageGroup.uploading ? this.progressBar : this.uploadElements}
        </DialogContent>
        <DialogActions>
          <Button
            label="Close"
            onClick={this.handleClose}
          >
          Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


// <Toggle
//  toggled={this.state.expanded}
//  onToggle={this.handleToggle}
//  labelPosition="right"
//  label="This toggle controls the expanded state of the component."
// />
// <CardTitle title="Card title" subtitle="Card subtitle" expandable={true} />
