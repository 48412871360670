import { computed, observable, extendObservable, action, toJS } from 'mobx'
import update from 'immutability-helper'


export default class Image{
  constructor(params, parent){
    //console.log('params for image', params, parent)
    extendObservable(this, params)
    this.imageGroup = parent
    this.showError = parent.showError
    this.showMessage = parent.showMessage
    this.emit = parent.emit
    this.emitStream = parent.emitStream
  }

  @computed get src(){
    //return(this.Location)
    return(this.Bucket + '/' + this.Key)
  }
  @computed get title(){
    return(this.oldURL)
  }
  @computed get authenticated(){
    return(this.imageGroup.ctradlex.treeNode.treeView.store.authenticated)
  }
  @computed get imagePosition(){
    let images = toJS(this.imageGroup.images)
    for (let i=0; i< images.length; i++){
      if(images[i]._id == this._id){
        return (i)
      }
    }
    return(-1)
  }
  @action setFocused = ()=>{
    this.imageGroup._focusedImage = this._id
    console.log('setting active: ', this._id)
  }

  @computed get isFocused(){
    console.log('active:', this.imageGroup._focusedImage)
    return(String(this._id) == String(this.imageGroup._focusedImage))
  }

  @action delete = async ()=>{
    if(!this.authenticated){return}
    let params = {imageId: this._id}
    let newImageGroup = await this.emit('deleteImage', params)
    this.imageGroup.buildImages(newImageGroup.images)
  }
  @action receiveDrop = (draggedImage)=>{
    this.imageGroup.reorderImages(draggedImage, this)
  }

}
