import {observer} from 'mobx-react'
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import styles from 'styles/styles.js'
import Image from 'components/Image'
import {
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import Progress from 'components/Progress'
import windowSize from 'react-window-size'


@withStyles(styles)
@observer
class EditCtradlexName extends React.Component{

  static propTypes = {
    classes: PropTypes.any.isRequired,
    ctradlex: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired
  }

  state = {
    newName: ''
  }

  handleSubmit = async ()=>{
    if(this.props.ctradlex.Name.length<2){
      this.props.ctradlex.treeNode.treeView.store.showError('title must be at least 2 characters long')
      return (false)
    }
    else{
      await this.props.ctradlex.updateDb()
      this.handleClose()
    }
    return true
  }

  handleClose = ()=>{
    let {onClose} = this.props
    onClose()
  }

  componentDidUpdate(){
  }

  render(){
    let {classes, open} = this.props

    let renderVal
    (this.props.ctradlex.Name == 'new entry') ? renderVal = '' : renderVal = this.props.ctradlex.Name

    return(
      <Dialog
        title="Edit Title"
        open={open}
        classes={{
          paper:classes.dialogSmall
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Edit Title'}</DialogTitle>
        <DialogContent>
          <TextField
            key={'edit new'}
            autoFocus
            value={renderVal}
            inputProps={{
              classes: {
                input: classes.textBox,
              },
            }}
            label='Title'
            type='text'
            fullWidth={true}
            margin="normal"
            variant="outlined"
            onChange={(e)=>{
                this.props.ctradlex.Name = e.target.value
            }}
            onKeyPress={(e)=>{
              if (e.key=='Enter'){
                this.handleSubmit()
              }}}
            onBlur={()=>{}}
          />
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleSubmit}>
          Submit
        </Button>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

@withStyles(styles)
@windowSize
@observer
class RandomImageGroup extends React.Component{

  static propTypes = {
    classes: PropTypes.any.isRequired,
    store: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired,
    windowWidth: PropTypes.any.isRequired,
    windowHeight: PropTypes.any.isRequired
  }

  state = {
    showText: false,
    zoomImage: null
  }

  handleClose = async ()=>{
    this.props.onClose()
  }


  componentDidUpdate(){
  }

  handleShowHideToggle = ()=>{
    this.setState({showText: !this.state.showText})
  }

  handleNextGroup = ()=>{
    let {store} = this.props
    this.setState({showText: false})
    store.getRandomImageGroup()

  }

  zoomImage = ()=>{
    let {zoomImage} = this.state
    let {classes} = this.props
    if (!zoomImage){return(<div/>)}
    return(
      <Dialog
        title="Image Details"
        open={zoomImage ? true : false}
        classes={{paper: classes.dialog}}
        onClose={()=>{this.props.onClose()}}
      >
        <DialogContent
          className={classes.imageEditContainer}
        >
          <Image
            src={zoomImage.src}
            fullScreen={true}
            cover={false}/>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>{this.setState({zoomImage: null})}}>
          Cancel
        </Button>
        </DialogActions>
      </Dialog>
    )
  }



  render(){
    let {classes, open, maxWidth = 150, store, windowWidth, windowHeight} = this.props
    //console.log('windowWidth:', windowWidth, ' windowHeight:', windowHeight)
    let {randomImageGroup, queryingImageGroup} = store
    if(!randomImageGroup){return(<div/>)}


    let {Name, _id} = randomImageGroup
    let {showText, zoomImage} = this.state
    let imageGroup = randomImageGroup.imageGroups[0]
    let {images, caption} = imageGroup

    let imagesToRender = []

    queryingImageGroup ? imagesToRender = <Progress open={true}/> : imagesToRender =
    images.map((image, index)=>(
      <div
        key= {'image'+index}
        onDoubleClick={()=>{
          console.log('double clicked it')
          this.setState({zoomImage: image})}
        }
      >
      <Image
        src={image.src}
        imgIndex={image.imgIndex}
        minWidth={Math.floor(0.25*windowWidth)}
        minHeight={Math.floor(0.25*windowHeight)}
        maxWidth={Math.floor(0.5*windowWidth)}
        maxHeight={Math.floor(0.5*windowHeight)}
      />
      </div>
    ))
    //console.log('rerendering imageGroupRandom', images)

    return(
      <Dialog
        classes={{paper: classes.randomImageGroup}}
        title="Edit Title"
        open={open}
      >
        <DialogTitle
          id="alert-dialog-title"
          disableTypography={true}
          >
          {showText ? <div onDoubleClick={()=>{
            console.log('double click')
            this.props.store.refreshTree({id: _id})
            this.handleClose()
        }
      }><Typography variant='h4'>{Name}</Typography></div> : <Typography variant='h4'>Imagegroup Quiz</Typography>}</DialogTitle>
        <DialogContent>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div
              style={{display:'flex', justifyContent: 'space-around', overflow: 'auto', flexGrow: 1}}
            >
              {imagesToRender}
            </div>
            {this.zoomImage()}
            {showText ? <Typography variant='h6' style={{flexGrow: 0}}>{caption}</Typography> : <div/>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleShowHideToggle}>
            {showText ? 'Hide' : 'Show'}
          </Button>
          <Button onClick={this.handleNextGroup}>
            Next Group
          </Button>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export {EditCtradlexName, RandomImageGroup}

//{randomImageGroup.imageGroups[0].images.map((image, index)=>{
//  let {src, imgIndex} = image
//  return(<NewImage src={src} imgIndex={imgIndex}/>)
//})}
