import React from 'react'
import {observer} from 'mobx-react'
//import {Subheader} from 'material-ui/Subheader';

//import ImageGroup from './ImageGroups/ImageGroup.js'
import ImageGroup from './ImageGroups/ImageGroupNew'
import {withStyles} from '@material-ui/core/styles'
import withDragDropContext from 'dnd/dnd.js'
import styles from 'styles/styles.js'

export default
@withDragDropContext
@withStyles(styles)
@observer
class ImageGroups extends React.Component{
  handleChange(e){
    this.setState({title: e.target.value})
  }
  changeTitle(title){
    this.setState({title})
  }

  render () {
    const {classes} = this.props
    const {imageGroups} = this.props.document
    const imageGroupElements = imageGroups.map(imageGroup => (
      <ImageGroup
        key={imageGroup._id}
        imageGroup={imageGroup}
      />
    ))
    return (
        <div>
          {imageGroupElements}
        </div>
    )
  }
}
