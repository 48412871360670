

import {asyncContainer, Typeahead} from 'react-bootstrap-typeahead';

const AsyncTypeahead = asyncContainer(Typeahead);
//import 'react-bootstrap-typeahead/css/Typeahead.css'
import React from 'react';
import {observer} from 'mobx-react'
import {toJS} from 'mobx'
import './CtradlexTypeahead/css/bootstrap.css'
import styles from 'styles/styles.js'
import {withStyles} from '@material-ui/core/styles'

export default
@withStyles(styles)
@observer
class CtradlexTypeahead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          options: []
        }
      }

    handleSearch(e) {
        console.log('searching e', e)
        //if (e.length>2 & e!=this.props.store.typeaheadSearchString){
          this.props.store.getTypeahead(e)
        //}

      }
    handleChanges(selected) {
        // console.log('Selected', selected.selected[0]._id)
        this.ctradlexStore.getByID(selected.selected[0]._id)
        this.treeViewStore.treeFromId(selected.selected[0]._id)
      }
    render() {
      let {getTypeahead, refreshTree, typeaheadResult} = this.props.store
      typeaheadResult = toJS(typeaheadResult)
      let {classes, onChange} = this.props
      return (
        <AsyncTypeahead
          isLoading={this.state.isLoading}
          className={classes.ctradlexSrchDialog}
          ref="typeahead"
          options={this.state.options}
          onSearch={async (srchString)=>{
            this.setState({isLoading: true})
            let searchResult = await getTypeahead(srchString)
            this.setState({
              isLoading: false,
              options: searchResult
          })
          }}
          onChange={async (selected)=>{
            console.log('srch handler', selected)
            //let srchId = selected[0]._id
            onChange({selected})
            //refreshTree({id:srchId})
            //selected.target.value = ''

            //this.clear()
            //console.log(this)
            //this.refs.typeahead.getInstance().clear()
          }
          }
          minLength={4}
          labelKey = {'Name'}
        />
      )
    }
  }
