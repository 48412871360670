import React, { Component, useEffect, useState } from 'react';

import {useStyles} from 'styles/styles.js'
import {useStore} from 'stores/newStore.js'

import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {observer} from 'mobx-react'
import styles from 'styles/styles.js'
import {withStyles} from '@material-ui/core/styles'
import {CircularProgress, Popover} from '@material-ui/core'
import cx from 'classnames'
import windowSize from 'react-window-size';


export const ProgressPopover = observer((props)=>{
  var {anchorEl = {}, open= false, handleClose = ()=>{}} = props
  let {current} = anchorEl
  const [isOpen, updateIsOpen]= useState(open)
  const [elementHeight, updateElementHeight] = useState(150)
  useEffect(()=>{
    updateIsOpen(open)
  },[open])
  useEffect(()=>{
    current ? updateElementHeight(current.clientHeight * 0.5) : console.log('invalid current')
  },[current])
  const classes = useStyles()
  const onClose = ()=>{
    updateIsOpen(false)
    handleClose()
  }

  let returnElement =
    <Popover
        classes={{
          paper: classes.progressPopover
        }}
        open={isOpen}
        onClick={onClose}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        anchorEl={anchorEl.current || null}
      >
      <CircularProgress
        variant='indeterminate'
        size = {elementHeight}

      />
    </Popover>
  return(
    current ? returnElement : null
  )

})



export default
@withStyles(styles)
@windowSize
@observer
class Progress extends Component {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired
  };

  state = {
    size: 0
  }

  progressRef = React.createRef()

  componentDidMount(){
    this.mounted = true
    let {clientHeight} = this.progressRef.current
    //console.log('new progress:', clientHeight)
    if(this.mounted){
      this.setState({size: (0.9*clientHeight)})
    }
  }

  componentWillUnmount(){
    this.mounted = false
  }
  //componentDidUpdate = this.componentDidMount

  render(){
    let {classes} = this.props
    let {open = false
    //  , classes
    } = this.props
    let {size} = this.state
    let progress =
    <div
      ref={this.progressRef}
      className = {classes.progress}
    >
      <CircularProgress
        variant='indeterminate'
        size={size}
      />
    </div>

    let blankElement =
      <div />

    let returnElement
    open ? returnElement = progress : returnElement = blankElement
    return(returnElement)
  }
}
