import React from 'react'
import {
  TextField
} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import styles from 'styles/styles.js'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'



export default
@withStyles(styles)
@observer
class TextBox extends React.Component{
  static propTypes = {
    classes: PropTypes.any.isRequired,
    caption: PropTypes.string.isRequired,
    textBoxValue: PropTypes.any.isRequired,
    authenticated: PropTypes.any.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCommit: PropTypes.func.isRequired
  }

  state = {
    rowsMax:0,
    textBoxText: ''
  }
  divElement = React.createRef()
  inputElement = React.createRef()
  componentDidMount(){
    let {textBoxValue} = this.props
    this.setState({textBoxText: textBoxValue})
    this.componentDidUpdate()


    //console.log('div element:', this.divElement.current)
    //console.log('input element:', this.inputElement)

    //let rowsMax = Math.floor(clientHeight/(1.25*12))-2
    //this.setState({rowsMax}, ()=>{console.log('this.state', this.state)})
  }
  componentDidUpdate(){
    let {caption} = this.props

      //console.log('textBoxElement', this.divElement.current.parentNode.clientHeight)
      let {clientHeight, scrollHeight=0} = this.divElement.current.parentNode
      //console.log('clientHeight:', clientHeight, ' : scrollHeight:',  scrollHeight)
      let rowsMax = Math.floor((clientHeight)/(14))
      //console.log('rowsMax:', rowsMax)
      if (rowsMax!=this.state.rowsMax){
        this.setState({rowsMax}, ()=>{
          //console.log('this.state', this.state)
        })}
  }
  updateTextBox = (e=>{
    //console.log('updating text box', e.target.value)
    //this.setState({textBoxText:e.target.value})
    this.props.textBoxValue = e.target.value
  })
  onCommitChanges = (e)=>{
    let {onUpdate, onCommit} = this.props
    onUpdate(e)
    onCommit()
  }
  render(){
    let {classes, textBoxValue, authenticated, onUpdate, caption, onCommit} = this.props
    let {rowsMax} = this.state
    //console.log('rerendering textBox', textBoxValue)
    return(
        <TextField
          id="caption text field"
          ref={this.divElement}
          value={textBoxValue}
          InputProps={{
            classes: {
              root: classes.textBox,
            },
          }}
          InputLabelProps={{
            classes:{
              root: classes.textFieldLabel
            }
          }}
          disabled={!authenticated}
          label={caption}
          fullWidth={true}
          rowsMax={rowsMax}
          multiline
          variant="outlined"
          onBlur={this.onCommitChanges}
          onChange={onUpdate}
        />
    )
  }
}
