import React, { Component } from 'react';
import update from 'immutability-helper';
import { findDOMNode } from 'react-dom';
//import TreeNode from './TreeView/TreeNode.js';
import TreeNode from './TreeView/TreeNodeNew.js';
import withDragDropContext from 'dnd/dnd.js';
import {observer} from 'mobx-react'
var cx = require('classnames');

require('./TreeView/react-ui-tree.less');
require('./TreeView/theme.less');
require('./TreeView/app.less');

import {withStyles} from '@material-ui/core/styles'
import styles from 'styles/styles.js'

export default
@withStyles(styles)
@withDragDropContext
@observer
class Container extends Component {
  constructor(props) {
    super(props);
    this.moveNode = this.moveNode.bind(this);


    {(nodeID)=>{
      console.log('triggering on selected')
      return CtradlexStore.getByID(nodeID)
    }}

    //this.props.store.onSelected =

    this.state = {

    };
    this.hoverNode = null
    this.dragNode = null
    this.rootNodes = []
  }

  moveNode(dragIndex, hoverIndex, hoverNode, dragNode, xOffset) {
  }

  focusActive(){
  }

  componentDidMount(){
  }
  componentDidUpdate(){
  }

  render() {
    //console.log('rerendering entire tree')
    //const { cards } = this.state;
    const {classes} = this.props

    const {store} = this.props
    //children.map((child)=>{
    //  this.rootNodes.push(child._id)
    //})
    return (
      <div className={classes.treeView}>
          <TreeNode
            treeViewNode = {store.tree}
            key={'treeview'}
          />
      </div>
    );
  }
}
