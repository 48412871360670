import {
  Paper,
  Popover,
  Typography,
  MenuList,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Icon,
  TextField,
} from '@material-ui/core';
import cx from 'classnames'
import CtradlexTypeahead from '../CtradlexTypeahead'
import {CtradlexSearch} from '../ctradlexSearch'
import {SearchPopover} from '../ctradlexSearch'
import {withStyles} from '@material-ui/core/styles'
import styles, {useStyles} from 'styles/styles.js'
import {observer} from 'mobx-react'
import React, {useEffect, useState} from 'react';

import {useStore} from '../../stores/newStore.js'

import {Delete, Link, LinkOff, NoteAdd, Edit} from '@material-ui/icons';

const NewEntry = observer((props)=>{
  const [nameValue, setNameValue] = useState('new entry')
  const {open=false, anchorEl, onClose=()=>{}, onCompleteAddNew = ()=>{}} = props
  const classes = useStyles()
  let nameError = false
  let helperText = ''
  if(!nameValue){
    nameError = true
    helperText = 'please type a valid name'
  }

  const handleClose = ()=>{
    onClose(nameValue)
  }
  const addNewHandler = ()=>{
    if(nameValue) {
      onCompleteAddNew(nameValue)
    }
    else{
      console.warn('cannot add a diagnosis without a name')
    }
  }
  return (
    <Popover
      open={open}
      classes={{
        paper: cx(classes.ctradlexSrchPopover, classes.ctradlexAddNew)
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <TextField
       fullWidth={true}
       error={nameError}
       helperText={helperText}
       inputRef={(input) => {
         input && input.focus()
       }}
       label='new entry name'
       defaultValue='new entry'
       variant='outlined'
       onKeyPress= {(e) => {
           if (e.key === 'Enter') {
             console.log('Enter key pressed');
             addNewHandler()
             // write your functionality here
           }
        }}
       onChange={(e)=>{
         setNameValue(e.target.value)
       }}
      />
      <Button
        variant='outlined'
        onClick={addNewHandler}
      >
      Create
      </Button>
    </Popover>
  )
})


export default
@withStyles(styles)
@observer
class TreeNode extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      open:false,
      linkChildVisible: false,
      addNewVisible: false
    }
  }

  handleRenameOpen = (component)=>{
    console.log('clicked rename element', component.currentTarget)
    this.props.treeViewNode.document.editCtradlexName = true
  }

  handleMenuClose = ()=>{
    const {setContextMenu, contextMenu} = this.props.treeViewNode
    console.log(contextMenu)
    setContextMenu({contextMenuOpen: false})
  }
  handleAddNewOpen = (component)=>{
    this.setState({
      addNewVisible: true,
      addNewAnchorEl: component.currentTarget
    })
    console.log('clicked add new')
  }

  handleAddNewClose = (component)=>{
    this.setState({
      addNewVisible: false,
    })
    console.log('clicked add new')
    this.handleMenuClose()
  }

  handleAddNewComplete = async (name = 'new entry')=>{
    const {addNew} = this.props.treeViewNode
    await addNew({name})
    this.handleAddNewClose()
    console.log('completed add new')
  }

  handleOpenLinkChild = (component)=>{
    this.linkChildAnchorEl = component.currentTarget
    component.preventDefault()
    this.setState({linkChildVisible: true})
    //this.handleMenuClose()
    //console.log('handler for link child', component.currentTarget)
  }

  handleCompleteLinkChild = async (id)=>{
    const {linkChild} = this.props.treeViewNode
    console.log('handling link child:', id)
    await linkChild(id)
    this.setState({linkChildVisible: false})
    this.handleMenuClose()
  }

  handleUnlinkFromParent = ()=>{
    const {unlinkFromParent} = this.props.treeViewNode
    unlinkFromParent()
    this.handleMenuClose()
  }

  handleDelete = ()=>{
    const {deleteEntry} = this.props.treeViewNode
    deleteEntry()
    this.handleMenuClose()
    console.log('handler for delete')
  }
  handleLinkChildClose = ()=>{
    this.setState({linkChildVisible: false})
  }
  render(){
    const {Name, contextMenu, linkChild} = this.props.treeViewNode
    const {contextMenuOpen, anchorEl} = contextMenu
    const {classes} = this.props
    return(
      <div>
        <Popover
          open = {contextMenuOpen}
          onClose = {this.handleMenuClose}
          anchorEl = {anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuList>
            <MenuItem
              onClick={this.handleAddNewOpen}
            >
              <NoteAdd/>
              <Typography>add new</Typography>
            </MenuItem>
            <MenuItem
              onClick={this.handleRenameOpen}
            >
              <Edit/>
              <Typography>rename</Typography>
            </MenuItem>
            <MenuItem
              onClick={this.handleOpenLinkChild}
            >
              <Link/>
              <Typography>link new child</Typography>
            </MenuItem>
            <MenuItem
              onClick={this.handleUnlinkFromParent}
            >
              <LinkOff/>
              <Typography>unlink child</Typography>
            </MenuItem>
            <MenuItem
              onClick={this.handleDelete}
            >
              <Delete/>
              <Typography>delete</Typography>
            </MenuItem>
          </MenuList>
        </Popover>

        <SearchPopover
          open={this.state.linkChildVisible}
          onChange={this.handleCompleteLinkChild}
          label={'search for child to link to ' + Name}
          onClose={this.handleLinkChildClose}
          anchorEl={this.linkChildAnchorEl}
        />

        <NewEntry
          open={this.state.addNewVisible}
          onChange={()=>{}}
          onCompleteAddNew={this.handleAddNewComplete}
          onClose={this.handleAddNewClose}
          anchorEl={this.state.addNewAnchorEl}
        />
      </div>
    )
  }
}
