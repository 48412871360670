import Layout from './components/LayoutNew'
import 'mobx-react-lite/batchingForReactDom'
import {render} from 'react-dom';
import React from 'react';
import {CssBaseline} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from 'styles/styles.js'
//<Layout />

  render(
      <ThemeProvider
        theme={theme}
        >
        <React.Fragment>
          <CssBaseline />
                      {
                        <Layout />
                      }
        </React.Fragment>
      </ThemeProvider>,
    document.getElementById('app')
  );

if (module.hot) {
  module.hot.accept();
  module.hot.dispose(function() {
  });
}
