import React, { Component, useEffect, useState, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {observer} from 'mobx-react'
import styles, {useStyles, useClass} from 'styles/styles.js'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import request from 'superagent'
import exifParser from 'exif-parser'
import * as loadImage from 'blueimp-load-image'
import windowSize from 'react-window-size'
import fileType from 'file-type'
import Progress from 'components/Progress'
import {makeStyles} from '@material-ui/core/styles';
//import { Document, Page } from 'react-pdf/dist/entry.webpack'
import { Document, Page } from 'react-pdf/dist/entry.webpack'

const RenderImage = (props)=>{
  let {fileBlob, imgIndex = -1, maxWidth = null, maxHeight = null, fullScreen = false, containerRef={}, zoom = false} = props
  var imageRef = React.createRef()
  var divWidth = 0,
  divHeight = 0,
  divOrientation = null

  useEffect(()=>{
    //let {current} = containerRef || imageRef
    //let {current} = imageRef
    //let {clientWidth, clientHeight} = current
    //if(maxWidth && maxHeight){
    //  divWidth = maxWidth
    //  divHeight = maxHeight
    //}
    //else{
    //  divWidth = clientWidth
    //  divHeight = clientHeight
    //}
  //  divOrientation = (divWidth > divHeight) ? 'landscape' : 'portrait'
  //  console.log({clientWidth, clientHeight, divOrientation})
    renderImage()
  },[imageRef])

  function renderOverlay(img){
    if(imgIndex>=0){
      let newIndex = String.fromCharCode(65+imgIndex)
      var ctx = img.getContext("2d");
      ctx.font = "20px Arial";
      ctx.fillStyle = 'white'
      ctx.strokeStyle = 'black'
      if (newIndex) {ctx.fillText(newIndex, 10, 20)}
    }
    //ctx.strokeText(index, 10, 20);
    return(img)
  }

  function renderImage(){
    let {current} = containerRef || imageRef
  //  let {current} = imageRef
    let{clientHeight, clientWidth} = current
    console.log({clientHeight, clientWidth})

    let renderProps = {canvas:true, meta:true, contain: true}

    if(clientHeight>clientWidth){
      renderProps = {...renderProps, maxHeight:clientHeight}
    }
    else {renderProps = {...renderProps, maxWidth:clientWidth}}
    if(fullScreen){
      renderProps = {...renderProps, maxWidth:clientWidth * .7, maxHeight:clientHeight * .97}
      if(zoom){
        renderProps.contain = false
        renderProps.cover = true
      }
    }

    console.log({renderProps})
    let callBack = (img, data)=>{
      //console.log('image', img)
      console.log('data info', data)
      let {originalWidth, originalHeight} = data
      while (imageRef.current.firstChild) {
        imageRef.current.removeChild(imageRef.current.firstChild);
      }
      imageRef.current.appendChild(renderOverlay(img))

      //this.imageRef.appendChild(img)

    }
    loadImage(fileBlob, callBack, renderProps )
  }
  return(
    <div
    style={{
      height:'100%',
      width:'100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    }}
    ref={imageRef}
    />
  )
}

const RenderPdf = (props)=>{
  let {fileBlob, maxHeight=50, maxWidth=50, fullScreen = true, containerRef=null} = props
  const [originalDimensions, setOriginalDimensions] = useState(null)
  const [divDimensions, setDivDimensions] = useState(null)
  const [pdf, setPdf] = useState(null)
  var divStyle = {width:'100%', height: '100%'}

const classes = useStyles()
  var renderRatio = 1
  var divStyle = {overflow:'hidden'}

  const [docStyle, setDocStyle] = useState({overflow:'auto'})

  if(divDimensions && pdf){
    let {originalDimensions} = pdf
    console.log(originalDimensions, divDimensions)
    if(divDimensions.height > divDimensions.width){
      renderRatio = divDimensions.height/originalDimensions.height
      divStyle = {
        height: divDimensions.height,
        width: originalDimensions.width*(renderRatio),
        overflow: 'auto'
      }
    }
    else if(fullScreen){
      let newWidth = divDimensions.width*.7
      renderRatio = newWidth/originalDimensions.width
      divStyle = {
        width: newWidth,
        height: originalDimensions.height*(renderRatio),
        overflow: 'auto'
      }
      divStyle={width: newWidth, height: divDimensions.height}
    }

    else {
      renderRatio = divDimensions.width/originalDimensions.width
      divStyle = {
        width: divDimensions.width,
        height: originalDimensions.height*(renderRatio),
        overflow: 'auto'
      }
    }

    console.log('set render Ratio:', renderRatio, ' and div style:', divStyle)
  }


  //console.log('returning class', returnVal)
  //let documentClass = returnVal
  let pdfRef = React.createRef()
  const myStyle = makeStyles({document:{width:50}, pdfDoc:{width:50, height:100}})
  //console.log('myStyle', myStyle)
  const [numPages, setNumPages] = useState(null)
  console.log('rendering pdf')

  useEffect(()=>{
    if(divDimensions){return}
    let {current} = containerRef || pdfRef
    let {clientHeight, clientWidth} = current

    setDivDimensions({
      height: clientHeight,
      width: clientWidth
  })

  //  (async ()=>{




  //  })()
    //if(maxHeight && maxWidth){return}

    //setRenderMaxHeight(clientHeight)
    //setRenderMaxWidth(clientWidth)
  }, [pdfRef.current])


  const onDocumentLoadSuccess = async (pdf) => {
    //setNumPages(pdf.numPages)
    console.log('loadingPdf', pdf)
    let pdfPage = await pdf._transport.pagePromises[0]
    let dimensions = pdfPage._pageInfo.view
    let width = dimensions[2]
    let height = dimensions[3]
    console.log('original pdf dimensions: ', {width, height})
    setPdf({
      numPages: pdf.numPages,
      originalDimensions:{height, width}
    })
    //setOriginalDimensions({height, width})

  }

  const pages = ()=>{
    let returnPages = []
    if (fullScreen && pdf){
      console.log('rendering full screen')
      for(let n=1; n< pdf.numPages; n++){
        console.log('rendering page:', n)
        returnPages.push(
          <Page
            pageNumber={n}
            key={'page' + n}
            scale={renderRatio}
            className={classes.pdfPage}
            renderMode={'canvas'}
            renderTextLayer={true}
            renderInteractiveForms={false}
            renderAnnotationLayer={false}
          />
        )
      }
    }
    else{
    returnPages.push(
        <Page
          pageNumber={1}
          scale={renderRatio}
          key={'page1'}
          renderMode={'canvas'}
          renderTextLayer={true}
          renderInteractiveForms={false}
          renderAnnotationLayer={false}
        />)
    }
    return(returnPages)
  }

  return(
    <div
    ref={pdfRef}
    >
      <Document
        file={fileBlob}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pages()}
      </Document>
    </div>
  )
}

const ImageNew = observer((props)=>{
  const {src, imgIndex = -1, maxDimension='width', containerRef = null, className='', fullScreen = false, zoom = false} = props
  const [loading, setLoading] = useState(true)
  const [imgFileType, setImgFileType] = useState('')
  const [fileBlob, setFileBlob] = useState('')
  const [maxWidth, setMaxWidth] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)

  const classes = useStyles()
  var isLoaded = false
  var orientation,
    parentNode,
    resizeObserver
  var imageRef = React.createRef()

  useEffect(() => {
    loadFile()
  }, [src])

  useEffect(() => {
    //renderImage()
  }, [containerRef])

  useEffect(() => {
    if(!loading){
      console.log('loaded:', imgFileType)
    }
  }, [loading])

  useLayoutEffect(() => {
    //let {clientWidth, clientHeight} = imageRef.current

    //console.log('trigger useLayoutEffect')
  //updateSize();
  const updateSize = ()=>{
    //renderImage()
    //console.log('window resize')
  }

  window.addEventListener("resize", updateSize);
  return () =>
    window.removeEventListener("resize", updateSize);
}, [imageRef.current]);

  async function loadFile(){
    isLoaded = false
    //console.log('image src:', src)
    let fileDown = await request
      .get(src)
      .responseType('blob')
    //fileBlob = fileDown.body
    //console.log('img returnVal', returnVal.imageBlob)
    var fileReader = new FileReader();
    return new Promise((resolve, reject)=>{
      fileReader
        .onloadend = (event)=>{
          let arrayBuffer = event.target.result
          let uint8Array  = new Uint8Array(arrayBuffer)
          setImgFileType(fileType(uint8Array).ext)
          let parser = exifParser.create(arrayBuffer)
          let parsed
          try{parsed = parser.parse()}
          catch(err){parsed = {tags:{}}}
          let {tags} = parsed
          if (tags.Orientation){
            orientation = tags.Orientation
          }
          isLoaded = true
          setFileBlob(fileDown.body)
          setLoading(false)
        }

      fileReader.readAsArrayBuffer(fileDown.body);
    })
  }
  function renderFile(){
    if(loading){return(<div>loading...</div>)}
    console.log('filetype', imgFileType)
    if(imgFileType=='pdf'){
      return(
        <RenderPdf
          fileBlob = {fileBlob}
          fullScreen = {fullScreen}
          containerRef = {containerRef}
          imgIndex = {imgIndex}

        />
      )
    }
    return(
      <RenderImage
        fileBlob = {fileBlob}
        imgIndex = {imgIndex}
        containerRef = {containerRef}
        fullScreen = {fullScreen}
        zoom = {zoom}
      />)
  }

  return(
    renderFile()
  )
})

export default ImageNew



//export default
@withStyles(styles)
@windowSize
@observer
class Image extends Component {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    src: PropTypes.any.isRequired,
    imageTitle: PropTypes.string,
    maxWidth: PropTypes.any,
    maxHeight: PropTypes.any,
    fullScreen: PropTypes.any,
    windowWidth: PropTypes.any,
    windowHeight: PropTypes.any,
    cover: PropTypes.any,
    imgIndex: PropTypes.number,
    minHeight: PropTypes.number,
    minWidth: PropTypes.number
  };

  state = {
    loading: true
  }
  imageProps = {}

  componentDidUpdate(){
    //this.componentDidMount()
  }

  async componentDidMount(){
    this.mounted = true

    this.imageData = await this.fetchImage()

    if (this.mounted) {
      this.setState({loading:false})
    }


  }

componentWillUnmount(){
  this.mounted = false;
}

  async fetchImage(){
    let returnVal = {}
    const {src} = this.props;
    let fileDown = await request
      .get(src)
      .responseType('blob')
    returnVal.fileBlob = fileDown.body
    //console.log('img returnVal', returnVal.imageBlob)
    var fileReader = new FileReader();
    return new Promise((resolve, reject)=>{
      fileReader
        .onloadend = (event)=>{
          returnVal.arrayBuffer = event.target.result
          returnVal.uint8Array  = new Uint8Array(returnVal.arrayBuffer)
          returnVal.fileType = fileType(returnVal.uint8Array)
          let parser = exifParser.create(returnVal.arrayBuffer)
          let parsed
          try{parsed = parser.parse()}
          catch(err){parsed = {tags:{}}}

          let {tags} = parsed
          console.log('parsed information:', tags)
          if (tags.Orientation){
            returnVal.orientation = tags.Orientation
          }
          //console.log('file type: ', returnVal.fileType)
          resolve(returnVal)
        }

      fileReader.readAsArrayBuffer(returnVal.fileBlob);
    })
  }

  get renderProps(){
    let {
      maxWidth = 0,
      maxHeight = 0,
      minHeight = 0,
      minWidth = 0,
      fullScreen=false,
      cover=false,
      windowWidth,
      windowHeight,
      imgIndex = -1
    } = this.props
    let updateProps
    if(fullScreen){
      updateProps = {
        maxWidth: Math.floor(0.8*windowWidth),
        maxHeight:Math.floor(0.85*windowHeight - 64),
        minHeight,
        minWidth,
        canvas: true,
        imgIndex,
        fullScreen: true
      }
      cover ? updateProps.cover = true : updateProps.contain = true
    }
    else{
      updateProps = {
        maxWidth,
        maxHeight,
        minHeight,
        minWidth,
        canvas: true,
        imgIndex: imgIndex
      }
    }
    let {imageData} = this
    Object.assign(updateProps, imageData)
    return(updateProps)
  }

  render(){
    let {loading} = this.state
    let {maxHeight, maxWidth} = this.renderProps

    if(loading){
      //console.log('still loading')
      return(
        <div style={{height:maxHeight, width:maxWidth}}><Progress open={loading}/></div>
      )
    }
    let {renderProps} = this
    let {fileType} = renderProps
    if(!fileType){return(<div/>)}
    //if(fileType.ext=='pdf'){
    //  return(<Pdf renderProps = {renderProps}/>)
  //  }
    //else{return(<Image2 renderProps = {renderProps}/>)}
    //let {ext} = fileType
    let returnImg = <Image2 renderProps = {renderProps}/>
    let returnPdf = <Pdf renderProps = {renderProps}/>

    let returnElement

    (fileType.ext=='pdf') ? returnElement = returnPdf : returnElement = returnImg
    return(returnElement)

  }
}

@withStyles(styles)
@windowSize
@observer
class Image2 extends Component {
  //imageRef = null;
  imageRef = React.createRef()

  setImageRef = element => {
    this.imageRef = element;
  };

  renderOverlay(img){
    let {imgIndex} = this.props.renderProps
    if(imgIndex>=0){
      let newIndex = String.fromCharCode(65+imgIndex)
      var ctx = img.getContext("2d");
      ctx.font = "20px Arial";
      ctx.fillStyle = 'white'
      ctx.strokeStyle = 'black'
      if (newIndex) {ctx.fillText(newIndex, 10, 20)}
    }
    //ctx.strokeText(index, 10, 20);
    return(img)
  }

  renderImage(){
    let{clientHeight, clientWidth} = this.imageRef.current
    //console.log('imageRef:', this.imageRef.current.parentNode.id)
    //console.log('imageRef:', this.imageRef)
    let {renderProps} = this.props
    renderProps.maxWidth = clientWidth
    renderProps.maxHeight = clientHeight

    renderProps = update(renderProps, {$merge: {
      maxWidth: clientWidth,
      maxHeight: clientHeight,
      minHeight: clientHeight,
      minWidth: clientWidth
    }})
    //console.log('renderProps:', renderProps)
    let {fileBlob} = renderProps
    //console.log('updating image element', renderProps)

    let callBack = (img)=>{
      while (this.imageRef.current.firstChild) {
        this.imageRef.current.removeChild(this.imageRef.current.firstChild);
      }
      this.imageRef.current.appendChild(this.renderOverlay(img))
      //this.imageRef.appendChild(img)
    }
    loadImage(fileBlob, callBack, renderProps)
  }

  componentDidUpdate(){
    this.componentDidMount()
  }

  componentDidMount(){
    this.mounted = true
    if(this.mounted){
      this.renderImage()
    }

  }
  componentWillUnmount(){
    this.mounted = false
  }

  render() {
    //const {fileBlob, height, classes, fullScreen} = this.props
    //console.log('rendering image')
    return (
      <div
        //ref={this.setImageRef}
        style={{height: '100%', width: '100%'}}
        ref={this.imageRef}
      >
      </div>
    )
  }
}

@withStyles(styles)
@windowSize
@observer
class Pdf extends Component {
  state = {
    numPages: 0,
    pageNumber: 1,
  }
  canvasRef = null;

  setCanvasRef = element => {
    this.canvasRef = element;
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    if (this.loaded && this.mounted){
      this.setState({ numPages });
    }
    //console.log('successful load')
  }

  renderOverlay(){
    let {imgIndex} = this.props.renderProps
    let canvas = this.canvasRef
    if(imgIndex>=0){
      let newIndex = String.fromCharCode(65+imgIndex)
      var ctx = canvas.getContext("2d");
      ctx.font = "20px Arial";
      ctx.fillStyle = 'black'
      ctx.strokeStyle = 'black'
      if (newIndex) {ctx.fillText(newIndex, 10, 20)}
    }
  }

  componentDidMount = this.componentDidUpdate

  componentDidUpdate(){
      this.mounted = true
      if(this.mounted){
        this.renderOverlay()
      }
  }

  componentWillUnmount(){
    this.mounted = false
  }

  render() {
    const { pageNumber, numPages } = this.state;
    let {renderProps,classes} = this.props
    console.log('pdf render props', renderProps)
    const {fileBlob, maxHeight, maxWidth, fullScreen} = renderProps
    //console.log('height', maxHeight)
    let pages = []
    if (fullScreen){
      for(let n=1; n< numPages; n++){
        pages.push(
          <Page pageNumber={n} width={maxWidth} key={'page' + n} className={classes.pdfPage}/>
        )
      }
    }
    else{
      pages.push(<Page pageNumber={1} height={maxHeight} key={'page1'}/>)
    }

    return (
      <div
      style={{position: 'relative', height: maxHeight, width:maxWidth}}
      >
        <canvas
        ref={this.setCanvasRef}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 1,
          overflow: 'disabled'
        }}/>
        <Document
          file={fileBlob}
          onLoadSuccess={this.onDocumentLoadSuccess}
          style={{width: maxWidth}}
        >
          {pages}
        </Document>
      </div>
    );
  }
}
