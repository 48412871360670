import React from 'react';
import {TextField} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import {observer} from 'mobx-react'
import styles from 'styles/styles.js'
import PropTypes from 'prop-types'

export default
@withStyles(styles)
@observer class
PearlsText extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pearlsText: this.props.store.document.Pearls,
      divHeight: 0
    }
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
  };
  componentDidMount() {
    //console.log('mounted the element')
    //this.resizeDom()
  }

  componentDidUpdate() {
    //this.resizeDom()
  }
  updatePearls = (e)=>{
    //const {Pearls} = this.props.store
    //console.log(this.props)
    this.setState({pearlsText: e.target.value})
  }
  commitChange =()=>{
    this.props.store.document.Pearls = this.state.pearlsText
    this.props.store.document.updateDb()
  }

  render() {
    const {classes, store} = this.props
    const {authenticated, document} = store
    const {Pearls} = document
    this.setState({pearlsText: Pearls})
    //this.calculateLineHeight()
    return (
      <TextField
        className = {classes.pearls}
        ref={ (divElement) => this.divElement = divElement}
        id="pearlsTextDiv"
        value={Pearls}
        disabled={!authenticated}
        label="Diagnosis Pearls"
        inputProps={{
          classes: {
            textarea: classes.textBox,
          },
        }}
        fullWidth
        multiline
        onChange={this.updatePearls}
        onBlur={this.commitChange}
        rowsMax={15}
        margin="normal"
        variant="outlined"
      />
    );
  }
}
