import cx from 'classnames'
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import {observer} from 'mobx-react'
import TreeViewContextMenu from './TreeViewContextMenu'


require('./react-ui-tree.less');
require('./theme.less');
require('./app.less');

import {withStyles} from '@material-ui/core/styles'
import styles from 'styles/styles.js'

const nodeSource = {
  beginDrag(props) {
    const {uniqueId, module, parent} = props
    return {
      id: props.id,
      treeViewNode: props.treeViewNode
    };
  },
};

const nodeTarget = {
  drop(props, monitor, component){
    const isOnlyOver = monitor.isOver({ shallow: true })
    if(!isOnlyOver){return}

    //escape the parent nodes
    const hoverNode = props.treeViewNode
    const dragNode = monitor.getItem().treeViewNode

    const hoverID = hoverNode.uniqueId
    const dragNodeID = dragNode.uniqueId

    const dragIndex = dragNode.index
    const hoverIndex = hoverNode.index;

    if (hoverID === dragNodeID) {return;}

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // check the left alignment of dragged element and dropzone
    const offset = monitor.getDifferenceFromInitialOffset()
    const isLeftAligned = offset.x < 20

    // for siblings, check for reorder siblings vs drop one sibling...
    // into another sibling
    if (hoverNode.parent._id == dragNode.parent._id){
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {return}

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {return}

      // check to see if should drop into sibling rather than reorder
      if(!isLeftAligned){
        //console.log('drop into sibling')
        hoverNode.completeDrop(dragNode)
        return
      }

      // finally reorder if they are siblings
      if (isLeftAligned){
        hoverNode.reorderSiblings(dragNode)
      }
    }

    // make sure not trying to drop into same parent
    else if (dragNode.parent._id == hoverNode._id){
      return;
    }

    // drop into different parent
    else if (isLeftAligned){
      hoverNode.completeDrop(dragNode)
      return
    }

    //set default null
      //hoverNode.tree.hovered = null

  },
  hover(props, monitor, component) {
    const isOnlyOver = monitor.isOver({ shallow: true })

    //escape the parent nodes
    if(!isOnlyOver){return}
    const hoverNode = props.treeViewNode
    const dragNode = monitor.getItem().treeViewNode

    const hoverID = hoverNode.uniqueId
    const dragNodeID = dragNode.uniqueId

    const dragIndex = dragNode.index
    const hoverIndex = hoverNode.index;

    if (hoverID === dragNodeID) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // check the left alignment of dragged element and dropzone
    const offset = monitor.getDifferenceFromInitialOffset()
    const isLeftAligned = offset.x < 20

    // for siblings, check for reorder siblings vs drop one sibling...
    // into another sibling
    if (hoverNode.parent._id == dragNode.parent._id){
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // check to see if should drop into sibling rather than reorder
      if(!isLeftAligned){
        hoverNode.expandNode()
        //console.log('hovering sibling', hoverNode)
        return
      }

      // finally reorder if they are siblings
      if (isLeftAligned){

        return
      }
    }

    // make sure not trying to drop into same parent
    else if (dragNode.parent._id == hoverNode._id){
      //hoverNode.clearHovered()
      return;
    }

    // drop into different parent
    else if (isLeftAligned){
      //hoverNode.expandNode()
      //console.log('hovering different parent')
      return
    }

    //set default null
      //hoverNode.tree.hovered = null
  }
};

var DragSourceDecorator = DragSource('Node', nodeSource,
    function(connect, monitor) {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        };
});

var DropTargetDecorator = DropTarget('Node', nodeTarget,
    function(connect, monitor) {
        return {
            connectDropTarget: connect.dropTarget(),
            isHovered: monitor.isOver({ shallow: true })
        };
});

 @observer
 class TreeNode extends Component {
  constructor(props) {
    super(props)
    this.state={
    }

    //console.log('tree node props', this.props)
    //console.log('tree node styles', styles)
    let {nodeRef} = this.props.treeViewNode
    this.nodeRef = React.createRef()
    nodeRef = this.nodeRef
    this.props.treeViewNode.nodeRef = this.nodeRef
    //React.createRef()
  }

  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    // index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    // id: PropTypes.any.isRequired,
    // text: PropTypes.string.isRequired,
  }

  render() {
    var styles = {};
    const {isDragging, connectDragSource, connectDropTarget } = this.props;
    const opacity = isDragging ? 0 : 1;
    var {Name, uniqueId} = this.props.treeViewNode
    // console.log('rendering: ', module)
    return connectDragSource(connectDropTarget(
      <div
        className={cx('m-node')}
        style={styles}
        key={'outer'+uniqueId}
        >
        <div
          className="inner"
          ref={this.nodeRef}
          key={'inner'+uniqueId}

          >
          {this.renderCollapse()}
          {this.renderName()}
        </div>
        {this.renderChildren()}
      </div>
    ));
  }
  componentDidMount(){
    //console.log(this.props.treeViewNode)
    const {nodeRef, Name, isActive, parent, selectNode} = this.props.treeViewNode
    let {scrollIntoView} = this.props.treeViewNode.treeView

    if(isActive && scrollIntoView){
      let nodeToScroll = nodeRef.current
      if(parent){
        //console.log('triggering node to scroll to', parent)
        nodeToScroll = parent.nodeRef.current
      }

      //console.log('focusing node: ', Name)
      nodeToScroll.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'})
      selectNode()
    }
  }

  renderName(){
    const {isHovered, isDragging, classes, treeViewNode} = this.props
    const {selectNode, Name, uniqueId, isActive, contextMenu, setContextMenu, nodeRef} = treeViewNode

    return(
      <div>
        <span
          className={cx(classes.node, {[classes.activeNode]: isActive}, {[classes.hovered]: (isHovered &! isDragging)})}
          key={uniqueId}
          onClick={selectNode}
          onDoubleClick={()=>{this.props.treeViewNode.document.editCtradlexName = true}}
          onContextMenu={(component)=>{
            //console.log('rightClick')
            //console.log(component)
            component.preventDefault()
            let newContextMenu = {
              contextMenuOpen: true,
              anchorEl: component.currentTarget,
            };
            setContextMenu(newContextMenu)
          }}
          >
          {Name}
        </span>

        <TreeViewContextMenu
          treeViewNode = {this.props.treeViewNode}
        />
      </div>
    )
  }

  renderCollapse() {
    var {toggleCollapse, hasChildren, collapsed, uniqueId} = this.props.treeViewNode;
      return (
        <span
          key={'collapse'+uniqueId}
          className={cx('collapse', hasChildren ? (collapsed ? 'caret-right' : 'caret-down') : 'caret-circle')}
          onMouseDown={function(e) {e.stopPropagation()}}
          onClick={toggleCollapse}
          >
        </span>
      );

    return null;
  }

  renderChildren() {
    const { classes, isHovered } = this.props
    var node = this.props.treeViewNode
    var {children, collapsed, uniqueId} = node
    if(collapsed || !children || !children.length){return(null)}
    else {
      var childrenStyles = {};
      if(collapsed) childrenStyles.display = 'none';
      if(collapsed){return(null)}
      childrenStyles['paddingLeft'] = styles.node.paddingLeft
      var NewInstance = new DropTargetDecorator(DragSourceDecorator(withStyles(styles)(TreeNode)))
      return (
        <div
          className="children"
          style={childrenStyles}
          key={'children'+uniqueId}
          >
            {
              children.map((child)=>{
                return(
                  <NewInstance
                    treeViewNode={child}
                    key={child.uniqueId}
                    moveNode={this.props.moveNode}
                  />
                )
              })
          }

        </div>
      );
    }
  }
}




export default DropTargetDecorator(DragSourceDecorator(withStyles(styles)(TreeNode)));
