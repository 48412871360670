import React from 'react';
import {Card, CardActions, CardHeader, CardMedia, CardContent, Tooltip, IconButton} from '@material-ui/core';
import {Button as FlatButton, TextField, Switch as Toggle, Typography} from '@material-ui/core';
import {ZoomOutMap as ZoomIn} from '@material-ui/icons';
import Image from 'components/Image'
//import injectTapEventPlugin from 'react-tap-event-plugin';
//import ImageGroupEdit from './ImageGroup/ImageGroupEdit.js'
import { DragSource, DropTarget } from 'react-dnd';
import ImageGroupEdit from './ImageGroup/ImageGroupEditNew'
import {observer} from 'mobx-react'
import {toJS} from 'mobx'
//import {ImageGroupEditArrange} from './ImageGroup/ImageGroupEdit/ImageGroupEditArrange.js'

//injectTapEventPlugin();
import {withStyles} from '@material-ui/core/styles'
import styles from 'styles/styles.js'

const imageGroupSource = {
  beginDrag(props) {
    //console.log('dragging image')
    //this.props.imageGroup
    const {imageGroup} = props
    return {
      id: imageGroup._id,
      imageGroup: imageGroup
    };
  },
};

const imageGroupTarget = {
  drop(props, monitor, component){
    //console.log('dragging image group')
    const {image} = props

    //const hoverNode = props.treeViewNode
    //const dragNode = monitor.getItem().treeViewNode

    const isOnlyOver = monitor.isOver({ shallow: true })

    //escape the parent nodes
    if(!isOnlyOver){return}
    //else{hoverNode.completeDrop()}
  },
  hover(props, monitor, component) {
    const hoverImageGroup = props.imageGroup
    //console.log('hoverImage:', hoverImage)
    const dragImageGroup = monitor.getItem().imageGroup

    const isOnlyOver = monitor.isOver({ shallow: true })
    if (!isOnlyOver || (hoverImageGroup._id == dragImageGroup._id)){return}
    hoverImageGroup.receiveDrop(dragImageGroup)
    //console.log('hovering image group')
    return

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // check the left alignment of dragged element and dropzone
    const offset = monitor.getDifferenceFromInitialOffset()
    const isLeftAligned = offset.x < 20
  }
};

var DragSourceDecorator = DragSource('Node', imageGroupSource,
    function(connect, monitor) {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        };
});

var DropTargetDecorator = DropTarget('Node', imageGroupTarget,
    function(connect, monitor) {
        return {
            connectDropTarget: connect.dropTarget(),
            isOver: monitor.isOver({ shallow: true })
        };
});

export default
@DropTargetDecorator
@DragSourceDecorator
@withStyles(styles)
@observer
class ImageGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      imgHeight: 180,
      noImageStyle: {
        height:50,
        textAlign: 'center',
        verticalAlign: 'middle'
      }
    };
  }

  handleExpandChange = (expanded) => {
    if (expanded) {
      this.setState({imgHeight:300})
    }
    else {
      this.setState({imgHeight:180})
    }
    this.setState({expanded: expanded});
  };

  handleToggle = (event, toggle) => {
    this.setState({expanded: toggle});
  };

  handleExpand = () => {
    this.setState({expanded: true});
  };

  handleReduce = () => {
    this.setState({expanded: false});
  };

  getCardContent = (imgLinks, shortCaption) => {
    const mediaCollapsed =
    <CardContent>
      {imgLinks}
      <Typography>{shortCaption}</Typography>
      <Tooltip title='Edit'>
      <div>
        <IconButton
          aria-label = "Edit"
          aria-haspopup="true"
          onClick={()=>{ctradlex.editImageGroup = _id}}
          color="inherit"
        >
          <ZoomIn />
        </IconButton>
        </div>
      </Tooltip>
    </CardContent>

    const mediaExpanded =
    <CardContent>
      {imgLinks}
      <Typography>{shortCaption}</Typography>
    </CardContent>

    if (this.state.expanded) {
      const {images, caption, _id} = this.props.imageGroup
      return(
        <ImageGroupEdit
          imageGroup={this.props.imageGroup}
          key={this.props.imageGroup.id}
          onClose={
            ()=>{this.setState({expanded:false})}
          }
          imgHeight={300}
        />
    )
    }
    else {

      return(mediaCollapsed)

    }
  }

  render() {
    const {classes,connectDragSource, connectDropTarget, imageGroup} = this.props
    const {images = [], caption, _id, ctradlex} = imageGroup
    const{editImageGroup} = ctradlex
    const expanded = (ctradlex.editImageGroup == _id)


    //console.log('images for imageGroup: ', toJS(images))
    const shortCaption = caption.substring(0, 100) + '...';
    const imgLinks = images.map((img, i) => (
      <div
        key={img.src}
        className={classes.imageRow}
      >
        <Image
          key={img.src}
          src={img.src}
          imgIndex={i}
        />
      </div>
      ))
    //console.log('imgLinks: ', imgLinks)
    const cardContent = this.getCardContent(imgLinks, shortCaption)

    return connectDragSource(connectDropTarget(
      //expanded={this.state.expanded}
      //onExpandChange={this.handleExpandChange}
      <div
        className = {classes.imageGroupContainer}
      >
        <Card
          sytle={{'touch-action': 'manipulation'}}
          onDoubleClick={(e)=>{
            //e.preventDefault()
            ctradlex.editImageGroup = _id
          }}
          >
          <CardContent>
          <div
          className={classes.imagesInGroup}

          >
            {imgLinks}
          </div>
            <div
              style={{
                display:'flex',
                alignItems: 'center'
              }}
            >
            <Typography style={{flex:1}} noWrap={true} variant='body1'>{caption}</Typography>
            <Tooltip title='Edit'>
            <div
            style={{flex:0}}
            >
              <IconButton
                aria-label = "Edit"
                aria-haspopup="true"
                onClick={()=>{ctradlex.editImageGroup = _id}}
                color="inherit"
              >
                <ZoomIn
                  color='action'
                />
              </IconButton>
              </div>
            </Tooltip>
            </div>
          </CardContent>
        </Card>
        <ImageGroupEdit
          open={expanded}
          imageGroup={imageGroup}
          key={_id}
          onClose={()=>{ctradlex.editImageGroup = null}}
          imgHeight={300}
        />
      </div>
    ))
  }
}
