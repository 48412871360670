import { computed, observable, extendObservable, action, toJS } from 'mobx'
import update from 'immutability-helper'
import request from 'superagent'
import fileType from 'file-type'
import Image from './imageStore.js'

export default class ImageGroup{
  fetching = false
  @observable editImage = null
  @observable uploading = false
  @observable addImageOpen = false
  @observable _focusedImage = ''
  constructor(params, parent){
    extendObservable(this, params)
    this.original = params
    this.ctradlex = parent
    this.socket = parent.socket
    this.emit = parent.emit
    this.emitStream = parent.emitStream
    this.showError = parent.showError
    this.showMessage = parent.showMessage
    this.buildImages(params.images || [])

  }
  @computed get focusedImage(){
    for(let i=0;i<this.images.length;i++){
      if (String(this.images[i]._id)==String(this._focusedImage)){
        return(this.images[i])
      }
    }
    return(null)
  }

  @action focusNextImage = ()=>{
    for(let i=0;i<this.images.length;i++){
      if (String(this.images[i]._id)==String(this._focusedImage)){
        if (i==this.images.length-1) {return}
        this._focusedImage = this.images[i+1]._id
        return
      }
    }
  }

  @action focusPreviousImage = ()=>{
    for(let i=0;i<this.images.length;i++){
      if (String(this.images[i]._id)==String(this._focusedImage)){
        if (i==0) {return}
        this._focusedImage = this.images[i-1]._id
        return
      }
    }
  }

  @action buildImages = (images)=>{
    //console.log('imagesparams:', params.images)
    //console.log('images:', images)
    images = images || []
    this.images = []
    for (let i=0; i<images.length; i++){
      let imageToAdd = new Image(toJS(images[i]), this)
      this.images.push(imageToAdd)
      if (i==0){
        this.activeImage = imageToAdd._id
      }
    }
  }
  @action uploadUrl = async (uploadUrl = '')=>{
    console.log('upload url', uploadUrl)
    let response = await request
      .get(uploadUrl)
      .responseType('blob')
      .catch(err=>{
        this.ctradlex.treeNode.treeView.store.showError('error loading file from URL: ', err.message)
        console.log(err.message)
        return(false)
      })

    console.log('found response for upload URL', response.body)
    await this.uploadImage(response.body)
    return(true)
  }

  @action uploadImage = async (uploadFiles)=>{
    if(!this.authenticated){return}
    if(!Array.isArray(uploadFiles)){
      let newArray = []
      newArray.push(uploadFiles)
      uploadFiles = newArray
    }
    //console.log(uploadFile)
    //this.uploadPreview = uploadFile.preview || null
    console.log('Request to upload new image')
    //console.log(uploadFile[0].size)
    let callback = (err, result)=>{
      if(err){
        this.showError({message: err})
      }
      console.log('file uploaded:', result)
    }
    this.uploading = true
    await Promise.all(
      uploadFiles.map(async uploadFile=>{
        return new Promise((resolve, reject)=>{
          var fileReader = new FileReader();
          fileReader
            .onloadend = async (event)=>{
              let typeResponse = fileType(new Uint8Array(event.target.result))
              if(!typeResponse){
                this.ctradlex.treeNode.treeView.store.showError('unsupported file type')
                this.uploading = false
                return(false)
              }
              let typeFile = typeResponse.mime.split('/')[0]

              console.log('file type:', typeFile, typeResponse.ext)
              if(typeFile!='image' && typeResponse.ext!='pdf'){
                this.ctradlex.treeNode.treeView.store.showError('unsupported file upload type')
                this.uploading = false
                return(false)
              }
              let {name, url='', type} = uploadFile
              var params = {
                imageGroupId: this._id,
                name: name,
                type: type,
                oldURL: url || name,
                callback: callback
              }
              uploadFile.params = params

              let newImage = await this.emitStream('uploadImage', uploadFile, params).catch(err=>{reject(err)})
              this.images.push(new Image(toJS(newImage), this))
              resolve(true)
            }
          fileReader.readAsArrayBuffer(uploadFile);
        })
      }
    )
    )
    this.uploading = false
    //await this.ctradlex.treeNode.expandNode({forceRefresh:true})
    return(true)
  }

  @action receiveDrop = (dragImageGroup)=>{
    console.log(this._id, ' received imageGroup drop from: ', dragImageGroup._id)
    let params = {dragImageGroup: dragImageGroup, dropImageGroup: this}
    this.ctradlex.reorderImageGroups(params)
  }

  @computed get imageGroupPosition(){
    let imageGroups = toJS(this.ctradlex.imageGroups)
    for (let i=0; i< imageGroups.length; i++){
      if(imageGroups[i]._id == this._id){
        return (i)
      }
    }
  }

  @computed get imageGroupCategories(){
    return this.ctradlex.treeNode.treeView.store.settings.imageGroupCategories
  }
  @computed get authenticated(){
    return(this.ctradlex.treeNode.treeView.store.authenticated)
  }
  @action delete = async()=>{
    return await this.ctradlex.removeImageGroup(this).catch(err=>{throw err})
  }
  @action reorderImages = async (draggedImage, droppedImage)=>{
    if(!this.authenticated||this.fetching){return}
    console.log('called reorderImages')
    let {images: originalImages} = this
    var newImages = update(this.images, {
      $splice: [
        [draggedImage.imagePosition, 1],
        [droppedImage.imagePosition, 0, draggedImage],
      ],
    })

    let params = {
      imageGroupId: this._id,
      draggedId: draggedImage._id,
      droppedId: droppedImage._id
    }
    //this.images = newImages
    newImages = await this.emit('reorderImages', params, this).catch(err=>{
      this.images = originalImages
    })
    this.buildImages(newImages)
  }
  @computed get imageOrder(){
    let returnSet = []
    for (let i=0; i<this.images.length; i++){
      returnSet.push(this.images[i]._id)
    }
    return(returnSet)
  }
  @computed get dbUpdates(){
    let returnVal = {}
    let propsToUpdate = ['imageGroupCategory', 'clinicalInformation', 'caption']

    propsToUpdate.map(prop=>{
      let newVal = toJS(this[prop]) || ''
      let oldVal = toJS(this.original[prop]) || ''
      //console.log('evaluating dbupdate: ', prop, '...', newVal, ':', oldVal)

      if(newVal!=oldVal){
        //console.log('found change in prop: ', prop)
        returnVal = update(returnVal, {$merge: {[prop]: newVal}})
      }
    })
    return (returnVal)
  }
  @action updateDb = ()=>{
    if(!this.authenticated){return}
    console.log('data to update', this.dbUpdates)
    let params = {
      imageGroupId: this._id,
      updates: this.dbUpdates
    }
    this.emit('updateImageGroup', params, this).catch(err=>{

    })
  }
}
