import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,

  IconMenu,
  MenuItem,
  Button as FlatButton,
  Switch as Toggle,
  Popover
} from '@material-ui/core';

import {SpeedDial, SpeedDialIcon, SpeedDialAction} from '@material-ui/lab';


import { withStyles } from '@material-ui/core/styles'

import {
  MoreVert as MoreVertIcon,
  Close as NavigationClose,
  CreateNewFolder as AddGroup,
  Edit,
  VpnKey as LoginButton,
  ExitToApp as LogoutButton,
  Check,
  Visibility,
  VisibilityOff,
  Menu,
  Person
} from '@material-ui/icons';
import { SnackbarProvider, withSnackbar } from 'notistack'
import {observer} from 'mobx-react'
import {Login, UserProperties} from 'components/UserComponents'
import {EditCtradlexName} from 'components/appDialogs'

import styles from 'styles/styles.js'

export default
@withStyles(styles)
@withSnackbar
@observer
class NavBar extends React.Component{
//function SimpleAppBar(props) {
constructor(props){
  super()
    this.state = {
      logged: true,
      editTitle: false,
      editArrange: false,
      loginVisible: false,
      userNameEntered: '',
      passwordEntered: '',
      userPropertiesVisible: false,
      speedDialOpen: false
    };
    this.emailRef = React.createRef()

}

handleNameChange=(e)=>{
  //console.log('handling name change')
  this.props.ctradlex.Name = e.target.value
}
handleNameChangeFinal=(e)=>{
  this.props.ctradlex.updateDb()
  this.setState({editTitle:false})
  //this.props.ctradlex.updateRadlex()
}
handleArrangeOpen=()=>{
  this.setState({editArrange:true})
  //console.log('clicked edit arrange')
}
handleArrangeSubmit=()=>{
  this.setState({editArrange:false})
  //console.log('clicked submit arrange')
}
handleAddImageGroup= async ()=>{
  this.handleSpeedDialClose()
  let {ctradlex} = this.props
  ctradlex.addImageGroup()


  //console.log('clicked add image group')
}

handleLogout=()=>{
  this.handleSpeedDialClose()
  this.props.ctradlex.treeNode.treeView.store.logout()
}

handleSpeedDialOpen = ()=>{
  this.setState({speedDialOpen: true})
}

handleSpeedDialClose = ()=>{
  this.setState({speedDialOpen: false})
}

handleSpeedDialClick = ()=>{
  this.setState({speedDialOpen: !this.state.speedDialOpen})
}

handleEditUser = ()=>{
  this.handleSpeedDialClose()
  this.setState({userPropertiesVisible: true})
  //console.log('clicked edit user')
}



visibilityToggleElement(){
  var {treeViewVisible, toggleTreeViewVisible} = this.props.ctradlex.treeNode.treeView
  let icon, tooltip
  if (treeViewVisible){
    icon = <VisibilityOff />
    tooltip = 'hide tree'
  }
  else{
    icon = <Visibility />
    tooltip = 'show tree'
  }
  return(
    <Tooltip title='ShowTree'>
      <div>
        <IconButton
          aria-label = {tooltip}
          aria-haspopup="true"
          onClick={()=>{toggleTreeViewVisible()}}
          color="inherit"
        >
          {icon}
        </IconButton>
      </div>
    </Tooltip>
  )
}
renderUnauthenticatedElements(){
  const { classes, enqueueSnackbar, ctradlex } = this.props
  const {Name, authenticated, treeNode} = this.props.ctradlex
  let nonAuthenticatedElement =
  <Tooltip title='Done'>
    <IconButton
      classes = {{root: classes.appBarIconContainer}}
      aria-label = "Login"
      aria-haspopup="true"
      disabled={false}
      onClick={()=>{
        this.setState({loginVisible: true})
      }}
      color="inherit"
    >
    <Login
      open={this.state.loginVisible}
      onClose = {()=>{this.setState({loginVisible:false})}}
      store = {this.props.ctradlex.treeNode.treeView.store}
    />
      <LoginButton />
    </IconButton>
  </Tooltip>

  return(nonAuthenticatedElement)
}

renderAuthenticatedElements(){
  const {classes} = this.props
  const {speedDialOpen} = this.state
  let actions = [
    { icon: <AddGroup />, name: 'Add Image Group', action: this.handleAddImageGroup},
    { icon: <Edit />, name: 'Edit Diagnosis Name', action:this.handleEditCtradlexNameOpen},
    { icon: <Person />, name: 'Edit Account', action:this.handleEditUser},
    { icon: <LogoutButton />, name: 'Logout', action: this.handleLogout},
  ]
  return(
  <SpeedDial
    ariaLabel="user"
    className={classes.speedDial}
    hidden={false}
    icon={<SpeedDialIcon icon={<Menu />}/>}
    onBlur={this.handleSpeedDialClose}
    onClick={this.handleSpeedDialClick}
    onClose={this.handleSpeedDialClose}
    onFocus={this.handleSpeedDialOpen}
    onMouseEnter={this.handleSpeedDialOpen}
    onMouseLeave={this.handleSpeedDialClose}
    open={this.state.speedDialOpen}
    direction={'down'}
  >
    {actions.map(action => (
      <SpeedDialAction
        key={action.name}
        icon={action.icon}
        tooltipTitle={action.name}
        onClick={action.action}
      />
    ))}
  </SpeedDial>
)
}


handleEditCtradlexNameOpen=()=>{
  this.editCtradlexNameOpen = true
}

get editCtradlexNameOpen(){
  let {ctradlex} = this.props
  return ctradlex.editCtradlexName
}
set editCtradlexNameOpen(isOpen){
  let {ctradlex} = this.props
  ctradlex.editCtradlexName = isOpen
}



componentDidUpdate(){
}

render(){
  const { classes, enqueueSnackbar, ctradlex } = this.props;
  //console.log('app bar props')
  //console.log(this.props)
  //ctradlex.treeNode.treeView.store.showError = function(err){
  //  console.log('database error: ', err)
  //  enqueueSnackbar(err, {variant: 'error'})
  //}
  //ctradlex.treeNode.treeView.store.showMessage = function(success){
  //  enqueueSnackbar(success, {variant: 'success'})
  //}

  const {Name, authenticated, treeNode} = this.props.ctradlex

  ctradlex.treeNode.treeView.store.showError = function(err){
    console.log('database error: ', err)
    enqueueSnackbar(err, {variant: 'error'})
  }
  ctradlex.treeNode.treeView.store.showMessage = function(success){
    enqueueSnackbar(success, {variant: 'success'})
  }


  return (
    <div className={classes.appBarRoot}>
      <AppBar
        className={classes.appBarRoot}
        position="static"
        color="default">
        <Toolbar>
          <div
          className={classes.appBarMainDiv}
          >
          {this.visibilityToggleElement()}
          <div
          className={classes.appBarGrow}>
            <Typography variant="h5" color="inherit" onDoubleClick={this.handleEditCtradlexNameOpen}>
              {Name}
            </Typography>
          </div>
          {authenticated ? this.renderAuthenticatedElements() : this.renderUnauthenticatedElements()}
          </div>
        </Toolbar>
      </AppBar>
      <UserProperties
        open={this.state.userPropertiesVisible}
        onClose={()=>{this.setState({userPropertiesVisible:false})}}
        user={this.props.ctradlex.treeNode.treeView.store.user}
      />
      <EditCtradlexName
        open={this.editCtradlexNameOpen}
        onClose={()=>{this.editCtradlexNameOpen = false}}
        ctradlex={this.props.ctradlex}
      />
    </div>
  )
}
}

//export default (withStyles(styles)(withSnackbar(NavBar)))
