import React from 'react';
import ReactDOM from 'react-dom'

import {
  MenuItem,
  Divider,
  TextField,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Button,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import styles from 'styles/styles.js'
import PropTypes from 'prop-types'
import NewImage from 'components/Image'
import cx from 'classnames'


import {
  DeleteForever as DeleteIcon,
  AddAPhoto as AddAnImage,
} from '@material-ui/icons';

import { DragDropContext } from 'react-dnd';
import {observer} from 'mobx-react'
import {toJS} from 'mobx'
import ImageGroupEditArrange from './ImageGroupEdit/ImageGroupEditArrangeNew'
import withDragDropContext from 'dnd/dnd.js';
//import ImageGroupEditArrange from './ImageGroupEdit/ImageGroupEditArrange.js'
import AddImage from './ImageGroupEdit/AddImageNew.js'
import ImageEdit from './ImageGroupEdit/ImageEditNew.js'
import TextBox from 'components/TextBox'



export default
@withStyles(styles)
@observer
class ImageGroupEdit extends React.Component {
//imageContainerRef = React.createRef()
textBoxContainerRef = React.createRef()
divElement = React.createRef()
state = {
      imageContainerRef: React.createRef(),
      expanded: false,
      imgHeight: this.props.imgHeight,
      open: false,
      errOpen: false,
      errMessage: 'delete images first',
      addImageVisible: false,
      editImageVisible: false,
      dialogOpen: false
    };

  handleOpen = () => {
  };

  handleClose = () => {
    this.props.imageGroup.updateDb()
    this.props.onClose()
  };

  handleDelete = async () => {
    let response = await this.props.imageGroup.delete()
      .catch(err=>{
        return false
      })
    if(response) {
      this.props.onClose()
      this.props.imageGroup.ctradlex.treeNode.expandNode({forceRefresh: true})
    }
  };

  handleRequestClose = () => {
    this.setState({errOpen: false});
  };

  handleUpdate = () => {
    this.props.imageGroup.updateDb()
    this.handleClose()
  };

  handleCancel = () => {
    this.props.imageGroup.resetImageGroup()
    this.handleClose()
  };

  handleAddImage = () => this.setState({addImageVisible: true})

  handleDialogClose = () =>{
    this.props.imageGroup.updateDb()
    this.setState({dialogOpen: false})
  }

  handleDialogOpen = ()=>{
    this.setState({dialogOpen: true})
  }

  handleImageTypeChange = (imgType)=>{

  }

  updateImageGroupCategory = (e)=>{
    this.props.imageGroup.imageGroupCategory = e.target.value
  }

  handleAddImageClose = ()=>{
    this.setState({addImageVisible: !this.state.addImageVisible})
  }

  updateCaption(e){
    this.props.imageGroup.caption = e.target.value
  }



  moveImage(dragIndex, hoverIndex) {
  }

  componentDidUpdate(){
    //console.log('image container dive parent', this.state.imageContainerRef.current.clientHeight)
    //console.log('textBox container', this.textBoxContainerRef.current.clientHeight)
  }
  componentDidMount(){
    //console.log('imageGorupEdit mounted', this.divElement)
    //this.setState({imageContainerRef: this.imageContainerRef})
  }
  renderFocusedImage(){
    var imageContainerRef = React.createRef()
    const{classes, imageGroup} = this.props
    let {focusedImage} = imageGroup
    if (!focusedImage){return(<div/>)}
    let {src, imagePosition} = focusedImage
    console.log('rendering focused image', src, imagePosition)
    return(
      <div
        id={'image key'}
        className={classes.focusedImageBig}
        //style={{height:50, width:50}}
        onDoubleClick={()=>{}}
        onClick={()=>{}}
        onWheel={(e)=>{
          let {deltaY} = e
          //console.log('scrolling main image', e.deltaY)
          //if(deltaY >= 2){imageGroup.focusPreviousImage()}
          //if(deltaY <= -2){imageGroup.focusNextImage()}
        }}
      >
        <NewImage
          src={src}
          imgIndex={imagePosition}
          maxDimension='height'
          containerRef={
            //null
            this.divElement
          }
          fullScreen={true}
        />
      </div>
    )
  }
  render() {
    const{classes, imageGroup} = this.props
    let {focusedImage} = imageGroup
    const {images, caption, _id, imageGroupCategories, imageGroupCategory = '', authenticated} = imageGroup
    const style = {
			display: 'flex',
			justifyContent: 'space-around',
			paddingTop: '20px'
		}
    var focusedImageDiv = <div/>
    if (focusedImage) {
      let {src} = focusedImage
      focusedImageDiv =
      <div
        id={'image key'}
        className={classes.focusedImageBig}
        onDoubleClick={()=>{}}
        onClick={()=>{}}
      >
        <NewImage
          src={src}
        />
      </div>
    }
    const selectsOptions = imageGroupCategories.map((content, index)=>(
      <MenuItem
        key = {'optionIndex'+index}
        value={content.name}
      >
      {content.name}
      </MenuItem>
    ))
    const actionsAuthenticated = [
      <FormControl
        style={{width:200}}
        key='form control'>
        <InputLabel
          htmlFor="age-native-helper"
          classes={{
            root:classes.backgroundText,
          }}
          >
          Image Category
        </InputLabel>
        <Select
           value={imageGroupCategory}
           onChange={this.updateImageGroupCategory}
           classes={{root:classes.selects}}
           inputProps={{
             name: 'value',
             id: 'age-simple',
           }}
          >
          {selectsOptions}
        </Select>
      </FormControl>,
      <Tooltip key='addAnImageTooltip' title='Add an Image'>
        <IconButton
          aria-label = "Add an image"
          onClick={this.handleAddImage}
        >
          <AddAnImage />
        </IconButton>
      </Tooltip>,
      <Tooltip key='deleteImageTooltip' title='delete'>
        <IconButton
          aria-label = "delete group"
          onClick={this.handleDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>,
      <Tooltip key='closeButtonTooltip' title='close the dialog'>
        <Button onClick={this.handleClose}>
          close
        </Button>
      </Tooltip>
    ];

    const actionsNonauthenticated = [
      <Tooltip key='closeButtonTooltip' title='close the dialog'>
        <Button onClick={this.handleClose}>
          close
        </Button>
      </Tooltip>
    ]

    const categories =
    <FormControl
      style={{flex:0}}
      key='form control'>
      <InputLabel
        htmlFor="age-native-helper"
        classes={{
          root:classes.backgroundText,
        }}
        >
        Image Category
      </InputLabel>
      <Select
         value={imageGroupCategory}
         onChange={this.updateImageGroupCategory}
         classes={{root:classes.selects}}
         inputProps={{
           name: 'value',
           id: 'age-simple',
         }}
        >
        {selectsOptions}
      </Select>
    </FormControl>

//className={classes.imageGroupContainer}
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle>Edit Image Collection</DialogTitle>
          <DialogContent
          className={cx(classes.flexRowContainer)}
          ref={this.divElement}
          >
          <div>
            <ImageGroupEditArrange
              imageGroup={this.props.imageGroup}
              actions={{
                onDoubleClick: (clickedImage)=>{
                  //console.log('double clicked an image')
                  this.props.imageGroup.editImage = clickedImage
                  //console.log(this.props.imageGroup.editImage)
                }
              }}
            />
          </div>
          <div>
          {this.renderFocusedImage()}
          </div>
          <div
            style={{flexGrow:1, width: '100%', minWidth: 100, marginLeft:20}}
          >
            <TextBox
              caption='caption'
              textBoxValue={this.props.imageGroup.caption}
              authenticated={authenticated}
              onCommit={e=>{this.props.imageGroup.updateDb()}}
              onUpdate={e=>this.props.imageGroup.caption=e.target.value}
            />
          </div>

          </DialogContent>
          <DialogActions>
            {authenticated ? actionsAuthenticated : actionsNonauthenticated}
          </DialogActions>
        </Dialog>
        <AddImage
          open={this.state.addImageVisible}
          onClose= {this.handleAddImageClose}
          imageGroup = {this.props.imageGroup}
        />
        <ImageEdit
          key='image edit'
          imageGroup ={this.props.imageGroup}
          settings = {this.props.settings}
        />
      </div>
    );
  }
}
