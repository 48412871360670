import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
//import { makeStyles } from '@material-ui/core/styles';

export var theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    useNextVariants: true,
  },
  overrides:{},
  props:{}
})

//console.log('current theme', theme)

var baselineSize = '1.25em'

const drawerWidth = '25%'

var palette = {
  highlight: theme.palette.primary.dark,
  backgroundText: theme.palette.text.secondary,
  highlightText: theme.palette.primary.light,
  backgroundHighlight: theme.palette.background.paper,
  background: theme.palette.background.default
}

var styles = {
  progress:{
    height:'100%',
    width:'100%',
    overflow: 'hidden'
  },
  progressPopover: {
    backgroundColor: 'transparent',
    display: 'flex',
    boxShadow: 'none',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backgroundText:{
    color: palette.backgroundText,
    '&.Mui-focused': {
      color: palette.backgroundText
    }
  },
  highlightText:{
    color: palette.highlightText
  },
  window: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    'touch-action': 'manipulation'
  },
  spacer: {
    marginTop: 10,
    marginBottom: 10,
    flex:0
  },
    appBar: {
      flexGrow: 0,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    speedDial: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(2),
      display: 'flex',
      flexGrow: 0,
      flexShrink: 0
    },
    appBarRoot: {
      flexGrow: 0,
      width: '100vw'
    },
    appBarMainDiv: {
      display: 'flex',
      justifyContent: 'spaceBetween',
      width: '100vw',
      alignItems: 'center'
    },
    appBarGrow: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '40px',
      flexGrow: 1,
      flexShrink: 1
    },
    appBarIconContainer:{
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 0,
      //paddingRight: '40px'
    },
    appBarMenuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    sideBar: {
      flexShrink: 0,
      display: 'flex'
    },
    selects: {
      minWidth: '100px'
    },
    sideBarPaper: {
      width: drawerWidth,
      height: 'calc(100%-64px)',
      top: 64,
      display: 'flex',
    },
    sideBarDiv: {
      display: 'flex',
      overflow: 'hidden'
    },
    mainDiv: {
      display: 'flex',
      flexGrow: 0,
      overflow: 'auto',
      flexDirection: 'row',
      height: '100vh',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    mainDivResponsive:{
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth
    },
    panel:{
      display: 'flex',
      flexGrow:0,
      overflow: 'hidden',
      flexDirection: 'column',
    },
    leftPanel: {
        paddingLeft: '10px',
        paddingRight: '0px',
    },
    middlePanel: {
      minWidth: '300px',
      width: '20%',
      marginLeft: '20px',
    },
    rightPanel: {
      overflow: 'auto',
      flex: 5,
      width: '80%',
      marginLeft: '20px',
      marginRight: '20px',
    },

      ctradlexSearch: {
          paddingBottom: '20px'
      },
      treeView: {
        flexGrow: 1,
        overflow: 'auto'
      },
      parentsContainer:{
        display:'flex',
        flexGrow: 0,
        flexDirection:'column',
        padding: 8,

      },
      parentsChips:{
        overflow: 'auto',
        flexGrow: 0,
        display: 'flex',
        flexWrap: 'wrap'
      },
      parentChip:{
        margin: 4,
      },
      parentChipLabel:{
        maxWidth: '100%',
        fontSize: baselineSize,
        flexWrap: 'wrap',
        display: 'table',
        'white-space': 'normal'
      },
      activeParentChip:{
        backgroundColor: palette.highlight
      },
      pearls:{
        flex: 1,
        overlow: 'auto',
        height: '100%'
      },
      textBox:{
        fontSize: baselineSize,
        lineHeight: baselineSize,
        paddingRight: 15,
        paddingTop: 8,
        backgroundColor: palette.backgroundHighlight,
        flexShrink:0
      },
      textFieldParent:{
        width:'100%',
        height:'100%',
        display: 'flex',
        overflow:'auto',
        alignSelf:'flex-start',
        flexGrow: 0,
        paddingTop: 4,
        marginTop: 20,
      },
      textFieldLabel:{
        paddingTop: 0
      },
      dialog:{
        maxHeight: '90vh',
        minHeight: '90vh',
        maxWidth: '90vw',
        minWidth: '90vw',
        overflow: 'disabled'
      },
      dialogSmall:{
        minHeight: '200px',
        maxWidth: '90vw',
        minWidth: '50vw',
        overflow: 'disabled'
        //display: 'flex'
      },
      randomImageGroup:{
        maxHeight: '90vh',
        minHeight: '90vh',
        maxWidth: '90vw',
        minWidth: '90vw',
        overflow: 'auto'
      },

      imageGroupContainer:{
        paddingBottom: '20px',
        touchAction: 'manipulation',
        flex: 0
      },

      imageGroupEditArrange:{
        //display: 'flex',
        //flexDirection: 'column',

        //flexWrap: 'wrap',
        //justifyContent: 'center'
        height:'100%',
        overflow: 'auto',
        marginRight: 20,
        flex: 0
      },
      img:{
        'image-orientation': 'from-image'
      } ,
      imageColumn: {
        imageOrientation: 'from-image',
        width: 75,
        //height: 150,
        overflow: 'hidden',
        marginBottom: 5,
        flex:0
      },
      imageRow: {
        imageOrientation: 'from-image',
        height: 100,
        minWidth: 10,
        '--custom': 'custom variable',
        display: 'flex',
        marginLeft: 5,
        flexShrink: 0
      },
      imagesInGroup:{
        display: 'flex',
        flexDirection:'row',
        overflow: 'auto',
        width: '100%'
      },
      defaultImageContainer: {
        height: 150,
        width: 150
      },
      smallImage: {
        minHeight: 100,
        minWidth: 100,
        flexShrink: 0
      },
      imageEditContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        width: '100%'
      },
      flexColumnContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      },
      flexRowContainer:{
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'hidden',
        alignItems:'right',

      },
      imagePlaceholder:{
        width:150,
        height: 150,
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      imageGroupsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%'
      },
      imageGroupsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%'
      },
      node: {
        paddingLeft: '10px',
        width: '100%',
        minWidth: '50',
        display: 'table',
        'white-space': 'normal',
        overflow: 'disabled'
      },
      activeNode: {
        backgroundColor: palette.highlight
      },
      hovered: {
        outlineWidth: '1px',
        outlineStyle: 'dashed',
        outlineColor: 'white'
      },
      dragging: {
        outlineWidth: '1px',
        outlineStyle: 'solid',
        outlineColor: 'white'
      },
      focusedImage: {
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineColor: palette.highlight
      },
      focusedImageBig: {
        //display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //flexBasis: '50%',
        //flexShrink: 0,
        'overflow': 'auto',
        //overflowY:'auto',
        //overflowX:'visible',
        height: '100%',
        width: '100%',
        flexGrow: 2,
        //maxWidth: '60%'
        //height: '75%',
        //minHeight: 200,
      },
      ctradlexSrchPopover:{
        width: "325px",
        paddingTop: '10px',
        paddingLeft: '2px',
        paddingRight: '2px',
        paddingBottom: '2px',
        borderRadius: '8px',
        opacity: 0.25,
        //display: 'flex'
      },
      ctradlexAddNew:{
        display: 'flex'
      },
      ctradlexSrchDialog: {
        opacity: 1,
        height: '60vh',
      },
      ctradlexMatchHighlight: {
        fontWeight: 700,
        color: palette.highlightText
      },
      ctradlexSrchDialogListbox: {
        maxHeight: "80vh",
        borderWidth: "2px",
        borderColor: palette.highlight,
        width: '100%',
        backgroundColor: palette.background,
      },
      ctradlexSrchDialogOptions: {
        '&:hover': {
           background: palette.highlight,
        },
      },

      dropZoneStyle: {
        width: 150,
        height: 150,
        borderWidth: 2,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5
      },
      dropZoneActiveStyle: {
        borderStyle: 'solid',
        backgroundColor: '#eee'
      },
      dropZoneRejectStyle: {
        borderStyle: 'solid',
        backgroundColor: '#ffdddd'
      },
      bottomBar: {
        display: 'flex',
        flexDirection: 'row',
        'justify-content': 'flex-end'
      },
      bottomBarLeft: {
        flex: 2,
      },
      pdfPage: {
        //marginBottom: 10,
        paddingBottom: 10,
      },
      pdfDocument: {width:50},
      bottomBarRight: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        'justify-content': 'space-between'
      },
      bottomLabel: {
        flexGrow: 0,
        'text-align-last': 'right',
        paddingLeft: 20
      }
    }

export default styles

export const useClass = (inputStyle)=>{
  console.log('making class', inputStyle)
  let returnStyle = makeStyles(inputStyle)
  return(returnStyle)
}

export const useStyles = makeStyles(styles)
