import { autorun, computed, observable, extendObservable, action, toJS } from 'mobx'

import {createRef} from 'react';
import update from 'immutability-helper'
import request from 'superagent'
import io from 'socket.io-client'
import React from 'react';
import assert from 'assert'


//const {promisify} = require('util')

import User from './userStore.js'
import Settings from './settingsStore'
import TreeView from './treeViewStore'
import Ctradlex from './ctradlexStore'

class Store {
  @observable loading = true
  @observable treeView
  @observable settings
  @observable typeaheadResult = []
  @observable authenticated = false
  @observable loginEmail = ''
  @observable loginPassword = ''
  @observable user = ''
  @observable totalImages=0
  @observable totalImageGroups=0
  @observable randomImageGroup = null
  @observable queryingImageGroup = false
  token = ''
  constructor(){
    //console.log('constructing new store')
    this.socket = io(window.location.origin)
      .on('connect', async ()=>{
        //console.log('mobx connected to socket')
        //await this.authenticate({email: 'ctrimble@gmail.com', password: 'newpassword'})
        let props = await this.emit('initialize', {}, this).catch(err=>{
        })

        //this.getRandomImageGroup()
        //console.log('authenticated', props)

        this.user = new User(props.user, this)

        //console.log('this user', this.user)

        this.authenticated = props.authenticated
        //this.user = props.user.firstName
        //console.log('tree2', props.tree2)
        console.log('tree', props.tree)
        this.treeView = new TreeView(props.tree, this)
        this.settings = new Settings(props.settings, this)
        //console.log('active node', toJS(this.treeView.activeNode))

        this.loading = false
      })
      .on('updateTotals', (props)=>{
        console.log('got totals')
        Object.assign(this, props)
      })
      .on('updatedRecord', (props)=>{
        console.log('updated record')
        console.log(props)
        let {document} = props
        //let nodesToUpdate = this.treeView.getNode(document._id)
        //nodesToUpdate.map(node=>node.updateDocument(document))
        //nodeToUpdate ? nodeToUpdate.updateDocument(document) : false
        //nodeToUpdate.document = this.document
      })
      .on('uploadProgress', progress=>console.log('upload progress:', progress))
      this.utilities = {
        showMessage: this.showMessage,
        showError: this.showError,
        getTypeahead: this.getTypeahead,
        emit: this.emit,
        emitStream: this.emitStream,
        socket: this.socket
      }
  }
  @action getRandomImageGroup = async ()=>{
    this.queryingImageGroup = true
    let randomImageGroup = await this.emit('getRandomImageGroup', {}, this).catch(err=>{})
    console.log('randomImageGroup from server', randomImageGroup)
    this.randomImageGroup = new Ctradlex(randomImageGroup, this)
    this.queryingImageGroup = false
    console.log('random image group:', this.randomImageGroup)
  }

  @action login = async ()=>{
    let params = {email: this.loginEmail, password: this.loginPassword}
    if (!this.loginEmail || !this.loginPassword){
      this.showError('please type email and password to logon')
      return(false)
    }
    let response = await this.emit('login', {user: params}).catch(err=>{
      this.loginPassword = ''
      this.logout()
      let message = err.response.body.message
      this.showError(message)
      return false
    })
    //console.log('authentication response:', response)
    this.loginEmail = ''
    this.loginPassword = ''
    this.authenticated = true
    let {user, token} = response
    localStorage.setItem('id_token', token)
    //console.log('authentication response', response)
    this.user = new User(user, this)
    return true
  }

  @action logout = async ()=>{
    //console.log('clicked logout')
    this.authenticated = false
    localStorage.removeItem('id_token')
    let guestUser = await this.emit('logout')
    //console.log('logged out', guestUser)
    this.user = guestUser.user
  }
  @observable working = false

  @action emit = async (msg, params, node={fetching: false}, showProgress = true)=>{

    node.fetching = true
    showProgress ? this.working = true : this.working = false
    let token = localStorage.getItem('id_token') || ''
    let response = await request
      .post('/' + msg)
      .set({'Authorization': 'Bearer ' + token})
      .send(params)
      .catch((err=>{
        console.log('err', err.response.text)

        let message = err.response.text || 'unspecified server error'
        if (err.response.status==401){
          this.authenticated = false
          localStorage.removeItem('id_token')
        }
        this.showError(message)
        node.fetching = false
        throw new Error(message)
      }))
    this.working = false
    node.fetching = false
    let {update, message, suppressMessage} = response.body
    !suppressMessage ? this.showMessage(message) : false
    return(update)
  }

  @action emitStream = async (msg, file, params, node={fetching: false})=>{
    let token = localStorage.getItem('id_token') || ''
    //console.log('uploading image from newStore')
    let response = await request
      .post('/uploadImage')
      .set({'Authorization': 'Bearer ' + token})
      .field('params', JSON.stringify(params))
      .attach('file', file)
      .catch(err=>{
        let message = err.response.data || 'unspecified server error'
        console.log(message)
        this.showError(message)
        node.fetching = false
        throw new Error(message)
      })
      //console.log(response)
      node.fetching = false
      let {update, message} = response.body
      this.showMessage(message)
      return(update)
    node.fetching = false
  }

  @action refreshTree = async (params)=>{
    let {id, parentId} = params
    //console.log('called refreshTree')
    assert(id, 'must include id to search from')
    this.fetchingTypeahead = false
    this.working = true
    let tree = await this.emit('treeFromId', params)

    console.log('tree', tree)
    //console.log(tree)
    this.treeView = new TreeView(tree, this)
    this.working = false
  }
  @observable fetchingTypeahead = false
  @action getTypeahead = async (srchString = '')=>{
    this.typeaheadResult = []
    //console.log('getTypeahead search string:', srchString)
    if(srchString.length <= 3){return []}
    this.fetchingTypeahead = true
    let result = await this.emit('getTypeahead', {srchString}, {} ,false)
    //console.log('typeahead result', result)
    this.typeaheadResult = result
    this.fetchingTypeahead = false
    return(result)
  }
  @action showError = (params)=>{
    let {message} = params
    console.log('error messaging system', message)
  }
  @action showMessage = (params)=>{
    let {message} = params
    console.log(message)
  }

}




var store = window.TreeViewStore = new Store
export default store

const storeContext = React.createContext(store)
export function useStore(){
  return(React.useContext(storeContext))
}
