import { computed, observable, extendObservable, action, toJS } from 'mobx'

import update from 'immutability-helper'

export default class User{
  @observable oldPassword = ''
  @observable newPassword1 = ''
  @observable newPassword2 = ''

  constructor(params, parent){
    extendObservable(this, params)
    this.original = params
    this.emit = parent.emit
    this.emitStream = parent.emitStream
    this.store = parent
    this.showError = parent.showError
    this.showMessage = parent.showMessage
  }x

  @computed get name(){
    let {firstName = '', lastName = ''} = this
    let returnVal = firstName
    if(lastName){returnVal += ' ' + lastName}
    return(returnVal)
  }

  @action handleChangePassword = async ()=>{
    if (this.newPassword1 != this.newPassword2){
      this.store.showError('passwords do not match')
      this.newPassword1 = ''
      this.newPassword2 = ''
      return(false)
    }
    let params = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword1
    }
    let newUser = await this.emit('updateUserPassword', params)
      .catch(err=>{
        //console.log('entered error on update password')
        this.oldPassword = ''
        this.newPassword1 = ''
        this.newPassword2 = ''
        return(false)
      })
      //return(true)
    if(newUser){return(true)}
  }

  @computed get authenticated(){
    return(this.store.authenticated)
  }

  @computed get dbUpdates(){
    let returnVal = {}
    let propsToUpdate = ['email', 'firstName', 'lastName']

    propsToUpdate.map(prop=>{
      let newVal = toJS(this[prop]) || ''
      let oldVal = toJS(this.original[prop]) || ''
      //console.log('evaluating dbupdate: ', prop, '...', newVal, ':', oldVal)

      if(newVal!=oldVal){
        //console.log('found change in prop: ', prop)
        returnVal = update(returnVal, {$merge: {[prop]: newVal}})
      }
    })
    return (returnVal)
  }

  @action updateDb = async ()=>{
    //console.log('updating db is authenticated', this.authenticated)
    if(!this.authenticated){return}
    //console.log('calling updateDb')
    if (!this.dbUpdates){
      //console.log('skipping empty db updates')
      return
    }
    let params = {updates: this.dbUpdates}
    let user = await this.emit('updateUser', params)
      .catch(err=>{
        return false
      })
    if(user){
      this.store.user = new User(user, this.store)
      return true
    }
  }
}
