import React from 'react'
import {observer} from 'mobx-react'

import {
  Paper,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import styles from 'styles/styles.js'
import {CircularProgress} from '@material-ui/core'
import {RandomImageGroup} from 'components/appDialogs'

export default
@withStyles(styles)
@observer

class BottomBar extends React.Component{
  state={
    randomImageGroupOpen: false
  }

  toggleRandomImageGroupOpen = ()=>{
    this.setState({randomImageGroupOpen: !this.state.randomImageGroupOpen})
  }

  componentDidMount() {

  }
  render (){
    const { classes, store } = this.props;
    const {user, totalImages, totalImageGroups, treeView} = store
    const {activeNode} = treeView
    let {bottomBar, bottomBarLeft, bottomBarRight, bottomLabel} = classes
    let activeIdText = ''
    if(activeNode.document._id){
      activeIdText = 'id: ' + activeNode.document._id
    }

    return (
      <div>
        <Paper >
          <div className = {bottomBar}>
            <Typography variant='h6' className = {bottomLabel}>{activeIdText}</Typography>
            <Typography variant='h6' onClick={this.toggleRandomImageGroupOpen} className = {bottomLabel}>total image groups: {totalImageGroups.toLocaleString()}</Typography>
            <Typography variant='h6' className = {bottomLabel}>total images: {totalImages.toLocaleString()}</Typography>
            <Typography variant='h6' className = {bottomLabel}>user: {user.name}</Typography>
          </div>
        </Paper>
        <RandomImageGroup
          open={this.state.randomImageGroupOpen}
          store={store}
          onClose={this.toggleRandomImageGroupOpen}
        />
      </div>
    )
  }
}
