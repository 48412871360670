import { computed, observable, extendObservable, action, toJS } from 'mobx'
import update from 'immutability-helper'
import ImageGroup from './imageGroupStore.js'

export default class Ctradlex{
  loading = {}
  fetching = false
  @observable editCtradlexName = false
  @observable editImageGroup = ''
  constructor(params, treeNode){
    //console.log('ctradlex', params)
    //let {socket, emit, emitStream, showError, showMessage} = treeNode
    extendObservable(this, params)
    this.original = params
    this.socket = treeNode.socket
    this.emit = treeNode.emit
    this.emitStream = treeNode.emitStream
    this.treeNode = treeNode
    this.showError = treeNode.showError
    this.showMessage = treeNode.showMessage
    //console.log('current Store: ', this.Name)
    this.imageGroups = []
    //params.imageGroups = params.imageGroups || []
    if (!Array.isArray(params.imageGroups)){
      //console.log('fixing image groups to be array')
      params.imageGroups = [params.imageGroups]
    }
    for (let i=0; i<params.imageGroups.length; i++){
      let imgGroup = toJS(params.imageGroups[i])
      if (imgGroup){this.imageGroups.push(new ImageGroup(toJS(params.imageGroups[i]), this))}
    }
    this.loading = {
      Name: true,
      Pearls: true
    }
  }
  @action buildImageGroups = (imageGroups)=>{
    imageGroups = imageGroups || []
    this.imageGroups = []
    for (let i=0; i<imageGroups.length; i++){
      let imgGroup = toJS(imageGroups[i])
      if (imgGroup){this.imageGroups.push(new ImageGroup(toJS(imageGroups[i]), this))}
    }
  }
  @action addImageGroup = async()=>{
    if(!this.authenticated){return}
    let params = {ctradlexId: this._id}
    let newImageGroup = await this.emit('addImageGroup', params, this)
    this.editImageGroup = newImageGroup._id

    let imgGroup = toJS(newImageGroup)
    this.editImageGroup = newImageGroup._id
    if (imgGroup){this.imageGroups.unshift(new ImageGroup(imgGroup, this))}
    //await this.treeNode.expandNode({forceRefresh:true})

    console.log('editImageGroup:', this.editImageGroup)

    console.log('newImageGroup', newImageGroup)
  }
  @action removeImageGroup = async(imageGroupToRemove)=>{
    if(!this.authenticated){return}
    //console.log('got request to delete imageGroup', imageGroupToRemove)
    let params = {imageGroupId: imageGroupToRemove._id}
    let {imageGroups: originalImageGroups} = this
    let newImageGroups = update(this.imageGroups, {
      $splice: [[imageGroupToRemove.imageGroupPosition, 1]]}
    )
    newImageGroups = await this.emit('removeImageGroup', params, this)
      .catch(err=>{
        //this.imageGroups = originalImageGroups
        console.log('error deleting image group:', err)
        //return false
        throw err
      })
    return true
    //this.buildImageGroups(newImageGroups)
    //this.imageGroups = newImageGroups
  }
  @action reorderImageGroups = async(params)=>{
    if(!this.authenticated){return}
    let {dragImageGroup, dropImageGroup} = params
    if(this.fetching){
      //console.log('still fetching')
      return
    }
    if(dragImageGroup._id == dropImageGroup._id){
      //console.log('same node')
      return
    }
    //console.log('not fetching')

    if(!this.authenticated){return}
    //console.log('called reorderImageGroups')
    let {imageGroups: oldImageGroups} = this
    var newImageGroups = update(this.imageGroups, {
      $splice: [
        [dragImageGroup.imageGroupPosition, 1],
        [dropImageGroup.imageGroupPosition, 0, dragImageGroup],
      ],
    })

    let paramsToEmit = {
      ctradlexId: this._id,
      draggedId: dragImageGroup._id,
      droppedId: dropImageGroup._id
    }
    newImageGroups = await this.emit('reorderImageGroups', paramsToEmit, this).catch(err=>{
      //console.log('catching err')
      //this.imageGroups = oldImageGroups
    })

    this.buildImageGroups(newImageGroups)
    //this.imageGroups = newImageGroups

  }

  @action updateDb = async ()=>{
    if(!this.authenticated){return}
    //console.log('calling updateDb')
    if (!this.dbUpdates){
      //console.log('skipping empty db updates')
      return
    }
    let params = {ctradlexId: this._id, updates: this.dbUpdates}
    await this.emit('updateCtradlex', params)
    //console.log(this.dbUpdates)
  }

  @computed get authenticated(){
    return(this.treeNode.treeView.store.authenticated)
  }
  @computed get dbUpdates(){
    let returnVal = {}
    let propsToUpdate = ['Name', 'Pearls']

    propsToUpdate.map(prop=>{
      let newVal = toJS(this[prop]) || ''
      let oldVal = toJS(this.original[prop]) || ''
      //console.log('evaluating dbupdate: ', prop, '...', newVal, ':', oldVal)

      if(newVal!=oldVal){
        //console.log('found change in prop: ', prop)
        returnVal = update(returnVal, {$merge: {[prop]: newVal}})
      }
    })
    return (returnVal)
  }
}
