import { computed, observable, extendObservable, action, toJS } from 'mobx'
import update from 'immutability-helper'


import Ctradlex from './ctradlexStore.js'

export default class TreeNode{
  nodesToMove = []
  treeView = {}
  originalChildren = []
  nodeRef = {}
  @observable contextMenu = {
    contextMenuOpen: false,
    anchorEl: null
  }
  @observable fetching = false
  constructor(params, treeView){
    //console.log('TreeNode params:', params)
    this.treeView = treeView
    this.showError = treeView.showError
    this.showMessage = treeView.showMessage
    let {socket} = treeView
    this.socket = socket
    this.emit = treeView.emit
    this.emitStream = treeView.emitStream
    params.scrollIntoView = true
    this.refreshTreeNode(params)
    //console.log('added props to TreeNode')
    //console.log(this)

  }
  @action setContextMenu = (params)=>{
    if (this.authenticated) this.contextMenu = update(this.contextMenu, {$merge: params})
  }


  @action refreshTreeNode = (params)=>{
    let {Name} = this
    if (Name) {Object.assign(this, params)}
    else{extendObservable(this, params)}
    let {scrollIntoView = false} = params
    this.updateDocument(params.document)

    //this.document = new Ctradlex(toJS(params.document), this)
    this._childrenElement = []
    for (let i = 0; i< params._childrenElement.length; i++){
      params._childrenElement[i].activeParent = this.document._id
      this._childrenElement.push(new TreeNode(toJS(params._childrenElement[i]), this.treeView))
      //console.log('loading child element: ', params._childrenElement[i].document.Name)
    }
    let {children} = this
    this.originalChildren = children
    if (params.setToActive){
      //console.log('found active:', this.Name)
      this.setActive({scrollIntoView})
    }
  }

  @action updateDocument = (doc)=>{
    this.document = new Ctradlex(toJS(doc), this)
  }

  @computed get Name(){
    let {document = {Name:''}} = this
    return (document.Name)
  }

  @computed get _id(){
    let {document = {_id:''}} = this
    return(document._id)
  }

  @computed get authenticated(){
    return(this.treeView.store.authenticated)
  }

  @action queueReceiveDrop = (dragNode)=>{
    if(!this.authenticated){return}
    this.treeView.hoverNode = this
    if (this.parent.uniqueId == dragNode.parent.uniqueId) {
      this.parent.reorderChildren(dragNode, this)
    }
    else {
      this.nodesToMove.push({
        ctradlexId: dragNode._id,
        oldParentId: dragNode.parent._id,
        newParentId: this.parent._id
      })
    }
  }

  @action clearUpdates = ()=>{
    this.nodesToMove = []
    if (this.originalChildren != this.children){
      this.children = this.originalChildren
    }
  }

  @computed
  get children(){
    return this._childrenElement
  }
  set children(newVal){
    this._childrenElement = newVal
    console.log('newChildren Val', toJS(newVal))
    let newDocChildren = []
    for (let i=0; i<toJS(newVal).length; i++){
      newDocChildren.push(newVal[i]._id)
    }
    this.document.children = newDocChildren
    //console.log('newDocChildren:', newDocChildren)
    //console.log('dbUpdates:', this.document.dbUpdates)
  }


  @computed get childrenOrdered(){
    let returnSet = []
    let childArray = toJS(this.document.children)
    let childElements = toJS(this._childrenElement)

    for(let i=0; i<childArray.length; i++){
      let childVal = childArray[i]
      for (let e=0;e<childElements.length;e++){
        let srchVal = childElements[e].document._id
        if(childVal==srchVal){
          returnSet.push(this._childrenElement[e])
          break
        }
      }
    }
    return(returnSet)
  }

  @action reorderSiblings = async (dragNode)=>{
    if(this.fetching){return}
    if (!this.authenticated){return}
    let dropNode = this
    if (dragNode.uniqueId == dropNode.uniqueId){return}
    if (dragNode.parent._id != dropNode.parent._id){return}
    var newChildren = update(this.parent.children, {
      $splice: [
        [dragNode.childPosition, 1],
        [dropNode.childPosition, 0, dragNode],
      ],
    })
    let callback = (err, result)=>{
      if (err){
        this.showError({message:err})
        this.parent.children = this.parent.originalChildren
      }
      this.showMessage({message:result})
    }
    let params = {
      ctradlexId: this.parent._id,
      draggedId: dragNode._id,
      droppedId: dropNode._id
    }
    console.log('reorder children params:', params)
    let result = await this.emit('reorderChildren', params, this).catch(err=>{})

    console.log('emit result', result)
    this.parent.children = newChildren
  }

  @action completeDrop = async (nodeToDrop)=>{
    if(!this.authenticated){return}
    let {_id, activeParent} = nodeToDrop
    console.log('moving ', nodeToDrop.Name, ' from ', nodeToDrop.parent.Name, ' to ', this.Name)
    if(!activeParent){
      this.treeView.store.showError('error moving object')
      return(new Error('error moving object'))
    }

    let params = {
      ctradlexId: _id,
      oldParentId: activeParent,
      newParentId: this._id
    }
    let response = await this.emit('moveCtradlex', params, this)

    console.log('done with drop')
    let {children: parentChildren} = nodeToDrop.parent
    parentChildren.map((child, index)=>{
      if (child._id==_id){
        parentChildren.splice(index, 1)
        console.log('removing child from old parent')
      }
    })
    nodeToDrop.parent.children = parentChildren
    await this.expandNode({forceRefresh: true})
    this.children.forEach(child=>{if (child._id==_id){child.setActive()}})

    //this.treeView.store.refreshTree({id: _id, parentId: this._id})
    //this.treeView.getNode(nodeToDrop._id)[0].setActive()
    //await this.expandNode({forceRefresh: true})
    //this.treeView.getNode(nodeToDrop._id)[0].setActive()
    //this.treeView.getNode(nodeToDrop._id)[0].setActive()
  }

  @action addNew = async (props)=>{
    if(!this.authenticated){return}
    let {name = 'new entry'} = props
    console.log('clicked addNew', props)
    let newNode = await this.emit('addCtradlex', {parentId: this._id, name: name}, this)
    await this.expandNode({forceRefresh:true})
    let nodeGenerated = this.treeView.getNode(newNode._id)[0]
    nodeGenerated.setActive()
    //nodeGenerated.document.editCtradlexName = true
  }

  @action linkChild = async (childToLink)=>{
    if(!this.authenticated){return}
    console.log('clicked link child', childToLink)
    let params = {ctradlexId: this._id, childToLink: childToLink}
    let returnVal = await this.emit('linkChild', params, this).catch(err=>{return})
    await this.expandNode({forceRefresh: true})
    return(true)
  }

  @action linkParent = async (parentToLink)=>{
    if(!this.authenticated){return}
    let params = {ctradlexId: this._id, parentToLink: parentToLink}
    console.log('linkParantParams', params)
    let returnVal = await this.emit('linkParent', params, this).catch(err=>{})
    await this.expandNode({forceRefresh:true})
    let oldParentNodes = this.treeView.getNode(parentToLink)
    await Promise.all(
      oldParentNodes.map(node=>{
        node.expandNode({forceRefresh:true})
        node.collapsed = false
      })
    )
    return(true)
  }

  @action unlinkChild = async (childToUnlink)=>{
    if(!this.authenticated){return}
    let params = {ctradlexId: this._id, childToUnlink: childToUnlink._id}
    //console.log('clicked unlink child', params)
    let returnVal = await this.emit('unlinkChild', params, this)
    await this.expandNode({forceRefresh: true})

    return(true)
  }

  @action unlinkParent = async (parentToUnlink)=>{
    if(!this.authenticated){return}
    console.log('clicked unlink parent:', parentToUnlink)
    let params = {ctradlexId: this._id, parentToUnlink: parentToUnlink}
    let returnVal = await this.emit('unlinkParent', params, this).catch(err=>{
      return
    })
    if (this.parent._id == parentToUnlink){
      this.treeView.store.refreshTree({id: this._id})

    }
    else {this.treeView.store.refreshTree({id: this._id, parentId: this.parent_id})}
    //let oldParentNodes = this.treeView.getNode(parentToUnlink)
    //await this.refreshTreeNode(returnVal)
    //await Promise.all(oldParentNodes.map(node=>node.expandNode({forceRefresh: true})))
  }

  @action unlinkFromParent = async()=>{
    if(!this.authenticated){return}
    //console.log('unlinking from parent', this)
    let parents = this.treeView.getNode(this.activeParent)
    await Promise.all(
      parents.map(parent=>parent.unlinkChild(this).catch(err=>{return}))
    )
  }

  @action deleteEntry = async ()=>{
    if(!this.authenticated){return}
    let response = await this.emit('removeCtradlex', {ctradlexId: this._id}, this).catch(err=>{
      return
    })

    if (response){
      await Promise.all(
        this.document.parents.map(async parent=>{
          let parentInstances = this.treeView.getNode(parent)
          await Promise.all(
            parentInstances.map(async parentInstance=>parentInstance.expandNode({forceRefresh:true}))
          )
        })
      )
      this.parent.setActive()
      //console.log('clicked delete')
      return
    }
  }

  @action setActive = async (params = {})=>{
    let {scrollIntoView = false} = params

    //console.log('setting node to active: ', this.document.Name)
    this.treeView.activeNode = this
    this.treeView.scrollIntoView = scrollIntoView

    let emitParams = {
      id: this._id,
      parentId: this.activeParent
    }
    this.emit('setActive', emitParams, this).catch(err=>{
      console.log(err)
    })
    return(true)
  }

  @computed get childPosition (){
    let siblings = this.parent.children
    for (let c=0; c<siblings.length; c++){
      if (siblings[c]._id == this._id){
        return(c)
      }
    }
    return(-1)
  }

  @computed get isActive(){return (this.treeView.activeNode._id == this._id)}

  @computed get hasChildren(){
    let children = this.document.children || []
    if (children.length) return (true)
    else {return false}
  }

  @computed get parent(){return(this.treeView.getNode(this.activeParent)[0])}

  @computed get uniqueId(){
    let parent = this.parent || {_id:'root'}
    let parentId = parent._id
    return parentId + '-' + this._id
  }
  @action toggleCollapse = ()=>{
    this.collapsed = !this.collapsed
    if(!this.collapsed && !this.childrenExpanded){this.expandNode()}
  }

  @action selectNode = async ()=>{
    if(!this.childrenExpanded){this.expandNode()}
    this.collapsed = false
    this.setActive()
  }

  @action expandNode = async (params={})=>{
    let {forceRefresh = false} = params
    if(!forceRefresh && this.childrenExpanded){
      //console.log('node already expanded')
      return
    }
    let result = await this.emit('expandNode', {ctradlexId:this._id, parentId:this.activeParent}, this)
    console.log('expand node result', result)
    this.refreshTreeNode(result)
    this.childrenExpanded = true
    this.collapsed = false
  }
}
