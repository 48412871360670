import React, { Component, useEffect, useState } from 'react';
import {useStyles} from 'styles/styles.js'
import {useStore} from 'stores/newStore.js'
import update from 'immutability-helper';
import withDragDropContext from 'dnd/dnd.js';
import NewImage from 'components/Image'
//import exif from 'exif-reader'
import { DragSource, DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import {Typography} from '@material-ui/core'

import {observer} from 'mobx-react'
import styles from 'styles/styles.js'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'


const imageSource = {
  beginDrag(props) {
    //console.log('dragging image')
    const {uniqueId, module, parent} = props
    return {
      id: props.image_id,
      image: props.image
    };
  },
};

const imageTarget = {
  drop(props, monitor, component){
    const {image} = props

    //const hoverNode = props.treeViewNode
    //const dragNode = monitor.getItem().treeViewNode

    const isOnlyOver = monitor.isOver({ shallow: true })

    //escape the parent nodes
    if(!isOnlyOver){return}
    //else{hoverNode.completeDrop()}
  },
  hover(props, monitor, component) {
    const hoverImage = props.image
    //console.log('hoverImage:', hoverImage)
    const dragImage = monitor.getItem().image

    const isOnlyOver = monitor.isOver({ shallow: true })
    if (!isOnlyOver || (hoverImage._id == dragImage._id)){return}
    hoverImage.receiveDrop(dragImage)
    return

    //console.log('hovering', props)

    //escape the parent nodes
    //if(!isOnlyOver){return}

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // check the left alignment of dragged element and dropzone
    const offset = monitor.getDifferenceFromInitialOffset()
    const isLeftAligned = offset.x < 20

    // for siblings, check for reorder siblings vs drop one sibling...
    // into another sibling
    //if (hoverNode.parent._id == dragNode.parent._id){
      // Dragging downwards
    //  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //    return;
    //  }

      // Dragging upwards
    //  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    //    return;
    //  }

      // check to see if should drop into sibling rather than reorder
    //  if(!isLeftAligned){
    //    hoverNode.queueReceiveDrop(dragNode)
    //    return
    //  }

      // finally reorder if they are siblings
    //  if (isLeftAligned){hoverNode.queueReceiveDrop(dragNode)}
    //}

    // make sure not trying to drop into same parent
    //else if (dragNode.parent._id == hoverNode._id){
    //  hoverNode.clearHovered()
    //  return;
    //}

    // drop into different parent
    //else if (isLeftAligned){
    //  hoverNode.queueReceiveDrop(dragNode)
    //  return
    //}

    //set default null
      //hoverNode.tree.hovered = null
  }
};

var DragSourceDecorator = DragSource('Node', imageSource,
    function(connect, monitor) {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        };
});

var DropTargetDecorator = DropTarget('Node', imageTarget,
    function(connect, monitor) {
        return {
            connectDropTarget: connect.dropTarget(),
            isOver: monitor.isOver()
        };
});

@DropTargetDecorator
@DragSourceDecorator
@withStyles(styles)
@observer
class Image extends Component {
  constructor(){
    super()
  }

  render(){
    const {image, imgIndex, isDragging, isOver, connectDragSource, connectDropTarget, classes } = this.props;
    const {hovered, dragging, focusedImage} = classes
    const {src, title, isFocused} = image
    //console.log('is active:', isFocused)

    return connectDragSource(connectDropTarget(

      <div
        id={'image key'}
        className={cx(classes.imageColumn, {[focusedImage]: isFocused}, {[dragging]: isDragging}, {[hovered]: (isOver &! isDragging)})}
        //style={{height:50, width:50}}
        onDoubleClick={()=>{
          this.props.onDoubleClick(this.props.image)
        }}
        onClick={()=>{
          image.setFocused()
        }}
      >
      <NewImage
        src={src}
        imgIndex={imgIndex}
      />
      </div>
    ))
  }
}


export const imageGallery = withDragDropContext(
  withStyles(styles)(
    observer(()=>{
      return(<div/>)
    }
    )
  )
)




export default
@withDragDropContext
@withStyles(styles)
@observer
class ImageGroupEditArrange extends Component {
  constructor(props) {
  super(props);
  this.state={
    editImage: {
      open: false,
      image: null
    }
  }
}



render() {
  const {classes} = this.props
  const {images} = this.props.imageGroup

  const {onDoubleClick= ()=>{console.log('unassigned event double click')}} = this.props.actions
  const {onClick= ()=>{console.log('unassigned event click')}} = this.props.actions
  //console.log('on double click=', onDoubleClick)
  //console.log('on single click=', onClick)

  let imagesRendered =
  images.map((image, i) => (
    <Image
      image={image}
      imgIndex={i}
      key={image._id}
      onDoubleClick = {onDoubleClick}
      onClick = {onClick}
    />
  ))

  let noImages =
    <div className= {classes.imagePlaceholder}>
      <Typography> no images </Typography>
    </div>


  return (
    <div
      className={classes.imageGroupEditArrange}
      onScroll={(e)=>{
        let {scrollHeight, scrollTop, clientHeight} = e.target
        console.log('scrolling', {scrollHeight, scrollTop, clientHeight})}}
      >
      {(imagesRendered.length>0)? imagesRendered: noImages}
    </div>
  );
}
}
