import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  IconButton
} from '@material-ui/core';
import {ZoomIn, ZoomOut} from '@material-ui/icons';
import NewImage from 'components/Image'
//import ImageGroupEditArrange from './ImageGroupEditArrange.js'
import {observer} from 'mobx-react'
import styles from 'styles/styles.js'
import {withStyles} from '@material-ui/core/styles'

export default

@withStyles(styles)
@observer
class ImageEdit extends React.Component {
  constructor(props) {
    super(props);
  //  this.moveImage = this.moveCard.bind(this);
    this.state = {
      zoomIn: false,
      activeImage:null,
      dimensions:{
        height: null,
        width: null
      },
      imgStyle: {
        width: 'auto',
        height: 'auto',
        margin: 'auto',
        display: 'block'
      },
      imgContainerStyle: {
        display:'flex',
      },
      containerDivStyle: {}
    };
  }

  dialogRef = React.createRef()

  handleClose = () => {
    this.props.imageGroup.editImage = null
  };

  handleDelete = () => {
    this.props.imageGroup.editImage.delete()
    this.props.imageGroup.editImage = null
  };

  updateCaption(e){
    this.props.imageGroup.updateCaption(e.target.value)
    this.setState({open: false});
  }

  get image(){
    const {editImage} = this.props.imageGroup
    const {classes} = this.props
    let {zoomIn} = this.state
    if (editImage){
      if(zoomIn){
        return(
          <NewImage containerRef={this.dialogRef} src={editImage.src} fullScreen={true} zoom={true} cover={true}/>
        )
      }
      else{
        return(
          <NewImage containerRef={this.dialogRef} src={editImage.src} fullScreen={true} cover={false}/>
        )
      }
    }
    else{return('')}
  }

  get actions(){
    const {authenticated} = this.props.imageGroup
    const {zoomIn} = this.state

    const zoomInButton =
    <Tooltip title='zoom in' key='zoomin'>
    <div>
      <IconButton
        aria-label = "zoom in"
        aria-haspopup="true"
        onClick={()=>{this.setState({zoomIn: true})}}
      >
        <ZoomIn />
      </IconButton>
      </div>
    </Tooltip>

    const zoomOutButton =
    <Tooltip title='zoom out' key='zoomout'>
    <div>
      <IconButton
        aria-label = "zoom out"
        aria-haspopup="true"
        onClick={()=>{this.setState({zoomIn: false})}}
      >
        <ZoomOut />
      </IconButton>
      </div>
    </Tooltip>

    const actionsAuthenticated = [
      zoomIn ? zoomOutButton : zoomInButton,
      <Tooltip key='submitButtonTooltip' title='submit changes'>
        <Button onClick={this.handleClose}>
          submit
        </Button>
      </Tooltip>,
      <Tooltip key='deleteButtonTooltip' title='delete image'>
        <Button onClick={this.handleDelete}>
          delete
        </Button>
      </Tooltip>,
      <Tooltip key='cancelButtonTooltip' title='cancel'>
        <Button onClick={this.handleClose}>
          cancel
        </Button>
      </Tooltip>
    ]

    const actionsNonauthenticated = [
      zoomIn ? zoomOutButton : zoomInButton,
      <Tooltip key='cancelButtonTooltip' title='cancel'>
        <Button onClick={this.handleClose}>
          cancel
        </Button>
      </Tooltip>
    ]

    let returnVal
    authenticated ? returnVal = actionsAuthenticated : returnVal = actionsNonauthenticated
    return(returnVal)
  }

  render() {
    const {classes, imageGroup} = this.props
    var dialogOpen = false
    imageGroup.editImage ? dialogOpen = true : dialogOpen = false

    return (
      <Dialog
        title="Image Details"
        open={dialogOpen}
        classes={{paper: classes.dialog}}
      >
        <DialogContent
          className={classes.imageEditContainer}
          ref={this.dialogRef}
        >
          {this.image}
        </DialogContent>
        <DialogActions>
          {this.actions}
        </DialogActions>
      </Dialog>
    );
  }
}
