import {observer} from 'mobx-react'
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import styles from 'styles/styles.js'
import {
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'


@withStyles(styles)
@observer
class UserProperties extends React.Component{

  static propTypes = {
    classes: PropTypes.any.isRequired,
    user: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired
  }

  constructor(props){
    super(props)
    this.state = {
      changePasswordOpen: false
    }
  }

  handleSubmit = async ()=>{
    console.log('submitting')
    let submitSuccess = await this.props.user.updateDb()
      .catch(err=>{})
    if(submitSuccess){
      this.handleClose()
    }
  }

  handleClose = ()=>{
    let {onClose} = this.props
    onClose()
  }

  handleOpenChangePassword = ()=>{
    this.setState({changePasswordOpen:true})
  }

  handleCloseChangePassword = ()=>{
    this.setState({changePasswordOpen:false})
  }

  render(){
    let {classes, open} = this.props

    let fields = [
      {label: 'Email', updateValue: 'email', fieldType: 'text'},
      {label: 'First Name', updateValue: 'firstName', fieldType: 'text'},
      {label: 'Last Name', updateValue: 'lastName', fieldType: 'text'}
    ]

    return(
      <Dialog
        title="User Properties"
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{'User Properties'}</DialogTitle>
        <DialogContent>
          {fields.map(field=>{
            return(
              <TextField
                key={field.label}
                value={this.props.user[field.updateValue]}
                InputProps={{
                    className: classes.textBox,
                }}
                label={field.label}
                type={field.fieldType}
                fullWidth={true}
                margin="normal"
                variant="outlined"
                onChange={(e)=>{
                  this.props.user[field.updateValue] = e.target.value
                }}
                onKeyPress={(e)=>{
                  if (e.key=='Enter'){
                    //this.handleLogin()
                  }}}
                onBlur={()=>{}}
              />
            )
          })}
          <Button onClick={(this.handleOpenChangePassword)}>
            Change Password
          </Button>
          <ChangePassword
            open={this.state.changePasswordOpen}
            onClose={this.handleCloseChangePassword}
            user={this.props.user}
          />
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleSubmit}>
          Submit
        </Button>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}


@withStyles(styles)
@observer
class Login extends React.Component{

  static propTypes = {
    classes: PropTypes.any.isRequired,
    store: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired
  }

  constructor(props){
    super(props)
    this.state={
      loginEmail: '',
      loginPassword: ''
    }
  }

  handleLogin = async ()=>{
    this.props.store.loginPassword = this.state.loginPassword
    this.props.store.loginEmail =  this.state.loginEmail

    let logonSuccess = await this.props.store.login()
      .catch(err=>{})
    if(logonSuccess){this.handleClose()
      }
  }


  handleClose = ()=>{
    let {onClose} = this.props
    onClose()
  }

  handleUpdateEmail = ()=>{

  }


  render(){
    let {classes, open, store} = this.props
    let {loginEmail, loginPassword} = store
    return(
      <Dialog
        title="Login"
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{'Login'}</DialogTitle>
        <DialogContent>
          <TextField
            id="userName"
            autoFocus
            InputProps={{
                className: classes.textBox,
            }}
            value={this.state.loginEmail}
            label='user email'
            fullWidth={true}
            margin="normal"
            variant="outlined"
            onChange={(e)=>{
              this.setState({loginEmail: e.target.value})
            }}
            onBlur={()=>{}}
          />
          <TextField
            id="password"
            value={this.state.loginPassword}
            inputProps={{
              classes: {
                input: classes.textBox,
              },
            }}
            label='password'
            type="password"
            fullWidth={true}
            margin="normal"
            variant="outlined"
            onChange={(e)=>{
              this.setState({loginPassword: e.target.value})

            }}
            onKeyPress={(e)=>{if (e.key=='Enter'){this.handleLogin()}}}
            onBlur={()=>{}}
          />
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleLogin}>
          Submit
        </Button>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

@withStyles(styles)
@observer
class ChangePassword extends Component{

  static propTypes = {
    classes: PropTypes.any.isRequired,
    user: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired
  };
  constructor(props){
    super(props)
  }

  handleClose = ()=>{
    let {onClose} = this.props
    this.props.user.oldPassword = ''
    this.props.user.newPassword1 = ''
    this.props.user.newPassword2 = ''
    onClose()
  }

  handleChangePassword = async ()=>{
    let success = false
    success = await this.props.user.handleChangePassword()
      .catch(err=>{})
    console.log('finished handlePassword', success)
    if (success){
      console.log('user module success')
      this.handleClose()
    }
  }

  render(){
    let {classes, open, user} = this.props

    let fields = [
      {label: 'old password', updateValue: 'oldPassword', fieldType: 'password'},
      {label: 'new password', updateValue: 'newPassword1', fieldType: 'password'},
      {label: 'password repeat', updateValue: 'newPassword2', fieldType: 'password'}
    ]
    return(
      <Dialog
        title="Change Password"
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{'Change Password'}</DialogTitle>
        <DialogContent>
          {fields.map(field=>(
            <TextField
              key={field.label}
              value={this.props.user[field.updateValue]}
              InputProps={{
                  className: classes.textBox,
              }}
              label={field.label}
              type={field.fieldType}
              fullWidth={true}
              margin="normal"
              variant="outlined"
              onChange={(e)=>{
                this.props.user[field.updateValue] = e.target.value
              }}
              onKeyPress={(e)=>{
                if (e.key=='Enter'){
                  //this.handleLogin()
                }}}
              onBlur={()=>{}}
            />
          )
          )}
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleChangePassword}>
          Submit
        </Button>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export {UserProperties, Login, ChangePassword}
