
import React, {useEffect, useState} from 'react';

import {useStyles} from 'styles/styles.js'
import {useStore} from 'stores/newStore.js'
import { observer } from 'mobx-react'

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import matchSorter from 'match-sorter'


export function SearchPopover(props) {
  const{
    open = false,
    onChange,
    label,
    onClose = ()=>{},
    anchorEl,
  } = props
  const classes = useStyles();
  //const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    //setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    //setAnchorEl(null);
    onClose()
  };

  const id = open ? 'simple-popover' : undefined;

  return (
      <Popover
        id={id}
        classes={{
          paper: classes.ctradlexSrchPopover
        }}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <CtradlexSearch
          onChange = {onChange}
          label = {label}
          autofocus = {true}
        />
      </Popover>
  );
}


export const CtradlexSearch = observer((props)=>{
  const {
    onChange = (selectedId)=>{console.warn('no onChange event defined for search for id:', selectedId)},
    label = 'search...',
    autofocus = false
  } = props
  const [open, setOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [options, setOptions] = useState([]);
  const [srchString, setSrchString] = useState('');
  const [selection, setSelection] = useState('')

  const ctradlexstore = useStore()
  const classes = useStyles()
  const {ctradlexSrchDialog, ctradlexSrchDialogListbox, ctradlexSrchDialogOptions} = classes
  //console.log('css', ctradlexSrchDialogOptions)
  const {typeaheadResult, getTypeahead, refreshTree, fetchingTypeahead} = ctradlexstore

  const loading = false
  useEffect(() => {
    (async function typeaheadGetter(){
      setSearching(true)
      let optionResult = await getTypeahead(srchString)
      optionResult = matchSorter(optionResult, srchString, {keys: ['Name']})
      setOptions(optionResult)
      setSearching(false)
    })()
  }, [srchString])

  useEffect(()=>{
    (async()=>{
      setSearching(true)
      if (selection) {
        //console.log('setting selection')
        await onChange(selection)
        setSrchString('')
      }
      setSearching(false)
    })()
  }, [selection])


  return (
    <Autocomplete
      id="asynchronous-demo"
      //ListboxProps={{ style: { maxHeight: "80vh" }, position: "bottom-start" }}
      classes = {{
        listbox: ctradlexSrchDialogListbox,
        option: ctradlexSrchDialogOptions,
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={async (e)=>{
        let value = e.target.value
        setSrchString(value)
      }}
      onChange={(e, value)=>{
        setSelection(value._id)
        //onChange(value._id)
        //console.log('change event', toJS(value))
        }
      }
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.Name}
      noOptionsText='type to search...'
      options={options}
      loading={searching}


      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputRef={(input) => {
            if(autofocus){
              input && input.focus()
            }
          }}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {fetchingTypeahead ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.Name, inputValue);
        const parts = parse(option.Name, matches);
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                className={part.highlight ? classes.ctradlexMatchHighlight : ''}
                >
                  {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
}
)
