import cx from 'classnames'
import React from 'react';
import {Chip, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
//import CtradlexTypeahead from './CtradlexTypeahead'
import {CtradlexSearch, SearchPopover} from './ctradlexSearch'
import {createMuiTheme, withStyles} from '@material-ui/core/styles'
import {observer} from 'mobx-react'
import {toJS} from 'mobx'
var darkBaseTheme  = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    useNextVariants: true,
  },
})

import PropTypes from 'prop-types'



import styles from 'styles/styles.js'

export default
@withStyles(styles)
@observer
class ParentsChips extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        addParentVisible: false
      }
      this.styles = {
        chip: {
          margin: 4,
        },
        wrapper: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        activeChip: {
          margin: 4,
          backgroundColor: darkBaseTheme.palette.primary1Color,
          labelColor: darkBaseTheme.palette.alternateTextColor
        }
      }
      this.selectedNodeParent = null
    }
    static propTypes = {
      classes: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
    renderChip(data) {
      let {activeParent, authenticated} = this.props.store
      const isActive = (activeParent == data._id)
      const {classes} = this.props
      let authenticatedChip =
      <Chip
        key={data._id}
        label={data.Name}
        className={cx(classes.parentChip, {[classes.activeParentChip]:isActive})}
        classes={{label: classes.parentChipLabel}}
        onDelete={() => this.handleRequestDelete(data._id)}
        onClick={() => this.handleChipClick(data._id)}
      >
      </Chip>

      let nonAuthenticatedChip =
      <Chip
        key={data._id}
        label={data.Name}
        className={cx(classes.parentChip, {[classes.activeParentChip]:isActive})}
        classes={{label: classes.parentChipLabel}}
        onClick={() => this.handleChipClick(data._id)}
      >
      </Chip>

        return (
          authenticated ? authenticatedChip : nonAuthenticatedChip
        )
      }

      getBackColor(data){
          if (this.selectedNodeParent == data.id){
            //console.log('found active node')
            return this.styles.activeChip.backgroundColor
          }
          else{return}
        }

      handleRequestDelete = async (key) => {
        await this.props.store.unlinkParent(key)
      };

      handleChipClick = (key) => {
        let {activeParent} = this.props.store
        let isActive = (activeParent == key)
        if(isActive){
          //console.log('clicked the active parent')
          return
        }
        this.props.store.treeView.store.refreshTree({
          id: this.props.store._id,
          parentId: key
        })
        //this.props.store.ctradlexApp.treeViewStore.active.parent._id = key
        //this.props.store.focusParent(key)
        //console.log('You clicked the Chip', key);
      }
      handleAddNewParentOpen = (component)=>{
        this.linkChildAnchorEl = component.currentTarget
        this.setState({addParentVisible: true})
      }
      handleAddNewParentClose = ()=>{
        this.setState({addParentVisible: false})
      }
      handleLinkParent = async (id)=>{
        await this.props.store.linkParent(id)
        this.handleAddNewParentClose()
      }

  render() {
    const {classes} = this.props
    const {parentsExpanded = [], Name} = toJS(this.props.store.document)
    const {authenticated} = this.props.store
    //let chips = nodesToMap.map(this.renderChip, this)
    //chips.push()
    //nodesToMap.push({'_id': 'newParent', Name: 'Add Parent'})
    let addParentChip =
    <div>
      <Chip
        key={'newParent'}
        label={'add parent'}
        onClick={this.handleAddNewParentOpen}
        className={classes.parentChip}
      >
      </Chip>
      <SearchPopover
        open={this.state.addParentVisible}
        onChange={this.handleLinkParent}
        label={'search for parent to link to ' + Name}
        onClose={this.handleAddNewParentClose}
        anchorEl={this.linkChildAnchorEl}
      />
    </div>

    return (
      <div className = {classes.parentsChips}>
        {parentsExpanded.map(this.renderChip, this)}
        {authenticated ? addParentChip : ''}
      </div>
    )
  }
}
